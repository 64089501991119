<template>
    <!-- Tabelle mit Paginierung -->
    <div style="position: relative">
        <div style="position: absolute; z-index: 5; top: -38px; right: 12px">
            <filter-menu :fields="filterFields" @filtersUpdated="filtersUpdated" :dense="true"></filter-menu>
        </div>
        <v-data-table
            id="clustertable"
            :headers="headers(1)"
            :items="clusters"
            item-key="_id"
            show-expand
            single-expand
            expand-icon="mdi-chevron-down"
            :server-items-length="totalCount"
            :page.sync="currentPage"
            :loading="loading"
            :expanded.sync="expandedRows"
            :sort-desc.sync="sortDesc"
            :sort-by.sync="sortBy"
            :search.sync="search"
            :items-per-page.sync="itemsPerPage"
            @update:options="fetchClusters"
            @update:expanded="fetchClusterDetails(expandedRows[0], childLoading, view)">
            <!-- Erweiterte Ansicht für Keywords und Children -->
            <!-- header slot -->
            <template v-for="(header_entry, index) in headers(1)" v-slot:[`header.${header_entry.value}`]="{ header }">
                <span :key="index">
                    <span v-bind:style="{ width: header.width }">
                        <v-tooltip max-width="250" color="" top :disabled="header.tooltip ? false : true">
                            <template v-slot:activator="{ on }">
                                <span v-on="on" style="white-space: nowrap; text-transform: uppercase !important; font-weight: 500">
                                    {{ header.text }}
                                    <v-icon class="questionIcon" v-if="header.tooltip" x-small>mdi-help-circle-outline</v-icon>
                                </span>
                            </template>
                            <span>
                                <v-row no-gutters>
                                    <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                </v-row>
                                {{ header.tooltip }}
                            </span>
                        </v-tooltip>
                    </span>
                </span>
            </template>
            <template v-slot:item.name="{ item }">
                <div class="py-4" style="padding-left: -5px !important; font-size: 0.98rem !important">
                    <div class="d-flex">
                        <span class="ml-1 my-auto"> {{ item.name }} {{ "(" + $helpers.formatNumber(item.keyword_count) + ")" }}</span>
                        <v-menu offset-y rounded="lg" color="primary" transition="slide-y-transition" bottom v-if="expandedRows[0] && expandedRows[0] == item">
                            <template v-slot:activator="{ on }">
                                <v-btn small v-on="on" icon class="ml-auto my-auto">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list rounded dense>
                                <v-list-item @click=""> <v-icon small class="mr-2">mdi-pencil-plus</v-icon><v-list-item-title>Create New Content</v-list-item-title> </v-list-item>
                                <v-list-item @click=""> <v-icon small class="mr-2">mdi-key-plus</v-icon><v-list-item-title>Optimize Existing URL</v-list-item-title> </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <v-btn-toggle class="mt-1" mandatory color="primary" v-model="view" rounded v-if="expandedRows[0] && expandedRows[0] == item">
                        <v-btn x-small :disabled="item.children.length ? false : true"> <v-icon class="mr-1" x-small>mdi-sitemap</v-icon> cluster </v-btn>
                        <v-btn x-small> <v-icon class="mr-1" x-small>mdi-key</v-icon> keywords </v-btn>
                        <v-btn x-small> <v-icon class="mr-1" x-small>mdi-web</v-icon> urls </v-btn>
                    </v-btn-toggle>
                </div>
            </template>
            <template v-slot:item.keyword_count="{ item }">
                <v-chip outlined class="qchip" color="primary" style="">
                    {{ $helpers.formatNumber(item.keyword_count) }}
                </v-chip>
            </template>
            <template v-slot:item.cluster_search_volume="{ item }">
                <v-chip outlined class="qchip" color="primary" style="">
                    {{ $helpers.formatNumber(item.cluster_search_volume) }}
                </v-chip>
            </template>
            <template v-slot:item.cluster_performance="{ item }">
                <div v-bind="(performance = clusterPerformanceValues[item.cluster_performance])">
                    <v-chip class="white--text" :color="performance.color">
                        <v-icon class="mr-1" size="14px">{{ performance.icon }}</v-icon> {{ performance.text }}
                    </v-chip>
                </div>
            </template>
            <template v-for="competitor in selectedCompetitors" v-slot:[`item.domain_share.${competitor.domain}`]="{ item }">
                <v-chip outlined class="qchip" :color="getShareColor(item.domain_share[competitor.domain])" style="">
                    {{ item.domain_share[competitor.domain] ? Math.ceil(item.domain_share[competitor.domain] * 100) : 0 }}%
                </v-chip>
            </template>
            <template v-slot:item.threshold="{ item }">
                <v-chip outlined class="qchip" color="primary" style="">
                    {{ (item.threshold * 100).toFixed(0) + "%" }}
                </v-chip>
            </template>
            <!-- Rekursive Children -->
            <template v-slot:expanded-item="{ item }">
                <td :colspan="6 + selectedCompetitors.length" class="pa-0" style="max-width: 100%; overflow-x: auto; padding-left: 50px !important">
                    <v-data-table
                        v-if="item.children.length && view === 0"
                        id="2ndtable"
                        flat
                        style="border-radius: 0px"
                        :loading="childLoading"
                        :expanded.sync="childExpandedRows"
                        @update:expanded="fetchClusterDetails(childExpandedRows[0], child2Loading, view2)"
                        item-key="_id"
                        hide-default-headers
                        show-expand
                        single-expand
                        expand-icon="mdi-chevron-down"
                        :headers="headers(2)"
                        :items="item.children">
                        <!-- header slot -->
                        <template v-for="(header_entry, index) in headers(2)" v-slot:[`header.${header_entry.value}`]="{ header }">
                            <span :key="index">
                                <span v-bind:style="{ width: index == 1 ? '300px !important' : header.width }">
                                    <v-tooltip max-width="250" color="" top :disabled="header.tooltip ? false : true">
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on" style="white-space: nowrap !important; text-transform: uppercase; font-weight: 500">
                                                {{ index == 0 ? "Sub Cluster" : header.text }}
                                                <v-icon class="questionIcon" v-if="header.tooltip" x-small>mdi-help-circle-outline</v-icon>
                                            </span>
                                        </template>
                                        <span>
                                            <v-row no-gutters>
                                                <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                            </v-row>
                                            {{ header.tooltip }}
                                        </span>
                                    </v-tooltip>
                                </span>
                            </span>
                        </template>

                        <template v-slot:item.name="{ item }">
                            <div class="py-4" style="padding-left: -5px !important; font-size: 0.98rem !important">
                                <div class="d-flex">
                                    <span class="ml-1 my-auto"> {{ item.name }} {{ "(" + $helpers.formatNumber(item.keyword_count) + ")" }}</span>
                                    <v-menu offset-y rounded="lg" color="primary" transition="slide-y-transition" bottom v-if="childExpandedRows[0] && childExpandedRows[0] == item">
                                        <template v-slot:activator="{ on }">
                                            <v-btn small v-on="on" icon class="ml-auto my-auto">
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list rounded dense>
                                            <v-list-item @click=""> <v-icon small class="mr-2">mdi-pencil-plus</v-icon><v-list-item-title>Create New Content</v-list-item-title> </v-list-item>
                                            <v-list-item @click=""> <v-icon small class="mr-2">mdi-key-plus</v-icon><v-list-item-title>Optimize Existing URL</v-list-item-title> </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                                <v-btn-toggle mandatory class="ml-1" color="primary" v-model="view2" rounded v-if="childExpandedRows[0] && childExpandedRows[0] == item">
                                    <v-btn x-small :disabled="item.children.length ? false : true"> <v-icon class="mr-1" x-small>mdi-sitemap</v-icon> cluster </v-btn>
                                    <v-btn x-small> <v-icon class="mr-1" x-small>mdi-key</v-icon> keywords </v-btn>
                                    <v-btn x-small> <v-icon class="mr-1" x-small>mdi-web</v-icon> urls </v-btn>
                                </v-btn-toggle>
                            </div>
                        </template>
                        <template v-slot:item.keyword_count="{ item }">
                            <v-chip outlined class="qchip" color="primary" style="">
                                {{ $helpers.formatNumber(item.keyword_count) }}
                            </v-chip>
                        </template>
                        <template v-slot:item.cluster_search_volume="{ item }">
                            <v-chip outlined class="qchip" color="primary" style="">
                                {{ $helpers.formatNumber(item.cluster_search_volume) }}
                            </v-chip>
                        </template>
                        <template v-slot:item.cluster_performance="{ item }">
                            <div v-bind="(performance = clusterPerformanceValues[item.cluster_performance])">
                                <v-chip class="white--text" :color="performance.color">
                                    <v-icon class="mr-1" size="14px">{{ performance.icon }}</v-icon> {{ performance.text }}
                                </v-chip>
                            </div>
                        </template>
                        <template v-for="competitor in selectedCompetitors" v-slot:[`item.domain_share.${competitor.domain}`]="{ item }">
                            <v-chip outlined class="qchip" :color="getShareColor(item.domain_share[competitor.domain])" style="">
                                {{ item.domain_share[competitor.domain] ? Math.ceil(item.domain_share[competitor.domain] * 100) : 0 }}%
                            </v-chip>
                        </template>
                        <template v-slot:item.threshold="{ item }">
                            <v-chip outlined class="qchip" color="primary" style="">
                                {{ (item.threshold * 100).toFixed(0) + "%" }}
                            </v-chip>
                        </template>
                        <!--   2. Rekursive Children -->
                        <template v-slot:expanded-item="{ item }">
                            <td :colspan="10 + selectedCompetitors.length" class="pa-0" style="max-width: 100%; overflow-x: auto; padding-left: 50px !important">
                                <!-- <v-progress-linear indeterminate color="primary" v-if="childLoading || childExpandedRows.length == 0"></v-progress-linear> -->
                                <v-data-table
                                    v-if="item.children.length && view2 === 0"
                                    flat
                                    style="border-radius: 0px"
                                    id="3ndtable"
                                    :loading="child2Loading"
                                    item-key="_id"
                                    hide-default-headers
                                    show-expand
                                    single-expand
                                    expand-icon="mdi-chevron-down"
                                    :expanded.sync="child2ExpandedRows"
                                    @update:expanded="fetchClusterDetails(child2ExpandedRows[0], child3Loading, view3)"
                                    :headers="headers(3)"
                                    :items="item.children">
                                    <!-- header slot -->
                                    <template v-for="(header_entry, index) in headers(3)" v-slot:[`header.${header_entry.value}`]="{ header }">
                                        <span :key="index">
                                            <span v-bind:style="{ width: header.width }">
                                                <v-tooltip max-width="250" color="" top :disabled="header.tooltip ? false : true">
                                                    <template v-slot:activator="{ on }">
                                                        <span v-on="on" style="white-space: nowrap !important; text-transform: uppercase; font-weight: 500">
                                                            {{ index == 0 ? "Sub Cluster" : header.text }}
                                                            <v-icon class="questionIcon" v-if="header.tooltip" x-small>mdi-help-circle-outline</v-icon>
                                                        </span>
                                                    </template>
                                                    <span>
                                                        <v-row no-gutters>
                                                            <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                                        </v-row>
                                                        {{ header.tooltip }}
                                                    </span>
                                                </v-tooltip>
                                            </span>
                                        </span>
                                    </template>

                                    <template v-slot:item.name="{ item }">
                                        <div class="py-4" style="padding-left: -5px !important; font-size: 0.98rem !important">
                                            <div class="d-flex">
                                                <span class="ml-1 my-auto"> {{ item.name }} {{ "(" + $helpers.formatNumber(item.keyword_count) + ")" }}</span>
                                                <v-menu offset-y rounded="lg" color="primary" transition="slide-y-transition" bottom v-if="child2ExpandedRows[0] && child2ExpandedRows[0] == item">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn small v-on="on" icon class="ml-auto my-auto">
                                                            <v-icon>mdi-dots-vertical</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-list rounded dense>
                                                        <v-list-item @click="">
                                                            <v-icon small class="mr-2">mdi-pencil-plus</v-icon><v-list-item-title>Create New Content</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item @click="">
                                                            <v-icon small class="mr-2">mdi-key-plus</v-icon><v-list-item-title>Optimize Existing URL</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </div>
                                            <v-btn-toggle mandatory class="ml-1" color="primary" v-model="view3" rounded v-if="child2ExpandedRows[0] && child2ExpandedRows[0] == item">
                                                <v-btn x-small :disabled="item.children.length ? false : true"> <v-icon class="mr-1" x-small>mdi-sitemap</v-icon> cluster </v-btn>
                                                <v-btn x-small> <v-icon class="mr-1" x-small>mdi-key</v-icon> keywords </v-btn>
                                                <v-btn x-small> <v-icon class="mr-1" x-small>mdi-web</v-icon> urls </v-btn>
                                            </v-btn-toggle>
                                        </div>
                                    </template>
                                    <template v-slot:item.keyword_count="{ item }">
                                        <v-chip outlined class="qchip" color="primary" style="">
                                            {{ $helpers.formatNumber(item.keyword_count) }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:item.cluster_search_volume="{ item }">
                                        <v-chip outlined class="qchip" color="primary" style="">
                                            {{ $helpers.formatNumber(item.cluster_search_volume) }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:item.cluster_performance="{ item }">
                                        <div v-bind="(performance = clusterPerformanceValues[item.cluster_performance])">
                                            <v-chip class="white--text" :color="performance.color">
                                                <v-icon class="mr-1" size="14px">{{ performance.icon }}</v-icon> {{ performance.text }}
                                            </v-chip>
                                        </div>
                                    </template>
                                    <template v-for="competitor in selectedCompetitors" v-slot:[`item.domain_share.${competitor.domain}`]="{ item }">
                                        <v-chip outlined class="qchip" :color="getShareColor(item.domain_share[competitor.domain])" style="">
                                            {{ item.domain_share[competitor.domain] ? Math.ceil(item.domain_share[competitor.domain] * 100) : 0 }}%
                                        </v-chip>
                                    </template>
                                    <template v-slot:item.threshold="{ item }">
                                        <v-chip outlined class="qchip" color="primary" style="">
                                            {{ (item.threshold * 100).toFixed(0) + "%" }}
                                        </v-chip>
                                    </template>
                                    <!--   3. Rekursive Children -->
                                    <template v-slot:expanded-item="{ item }">
                                        <td :colspan="10 + selectedCompetitors.length" class="pa-0" style="max-width: 100%; overflow-x: auto">
                                            <!-- <v-progress-linear indeterminate color="primary" v-if="childLoading || childExpandedRows.length == 0"></v-progress-linear> -->
                                            <v-data-table
                                                v-if="item.children.length && view3 === 0"
                                                id="3ndtable"
                                                :loading="child3Loading"
                                                item-key="_id"
                                                hide-default-headers
                                                show-expand
                                                single-expand
                                                expand-icon="mdi-chevron-down"
                                                :headers="headers"
                                                :items="item.children">
                                                <!-- header slot -->
                                                <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                                                    <span :key="index">
                                                        <span v-bind:style="{ width: header.width }">
                                                            <v-tooltip max-width="250" color="" top :disabled="header.tooltip ? false : true">
                                                                <template v-slot:activator="{ on }">
                                                                    <span v-on="on" style="white-space: nowrap !important; text-transform: uppercase; font-weight: 500">
                                                                        {{ index == 0 ? "Sub Cluster" : header.text }}
                                                                        <v-icon class="questionIcon" v-if="header.tooltip" x-small>mdi-help-circle-outline</v-icon>
                                                                    </span>
                                                                </template>
                                                                <span>
                                                                    <v-row no-gutters>
                                                                        <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                                                    </v-row>
                                                                    {{ header.tooltip }}
                                                                </span>
                                                            </v-tooltip>
                                                        </span>
                                                    </span>
                                                </template>

                                                <template v-slot:item.name="{ item }">
                                                    <div class="py-4" style="padding-left: -5px !important; font-size: 0.98rem !important">
                                                        <div class="d-flex">
                                                            <span class="ml-1 my-auto"> {{ item.name }} {{ "(" + $helpers.formatNumber(item.keyword_count) + ")" }}</span>
                                                            <v-menu
                                                                offset-y
                                                                rounded="lg"
                                                                color="primary"
                                                                transition="slide-y-transition"
                                                                bottom
                                                                v-if="child2ExpandedRows[0] && child2ExpandedRows[0] == item">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn small v-on="on" icon class="ml-auto my-auto">
                                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-list rounded dense>
                                                                    <v-list-item @click="">
                                                                        <v-icon small class="mr-2">mdi-pencil-plus</v-icon><v-list-item-title>Create New Content</v-list-item-title>
                                                                    </v-list-item>
                                                                    <v-list-item @click="">
                                                                        <v-icon small class="mr-2">mdi-key-plus</v-icon><v-list-item-title>Optimize Existing URL</v-list-item-title>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-menu>
                                                        </div>
                                                        <v-btn-toggle mandatory class="ml-1" color="primary" v-model="view4" rounded v-if="child2ExpandedRows[0] && child2ExpandedRows[0] == item">
                                                            <v-btn x-small :disabled="item.children.length ? false : true"> <v-icon class="mr-1" x-small>mdi-sitemap</v-icon> cluster </v-btn>
                                                            <v-btn x-small> <v-icon class="mr-1" x-small>mdi-key</v-icon> keywords </v-btn>
                                                            <v-btn x-small> <v-icon class="mr-1" x-small>mdi-web</v-icon> urls </v-btn>
                                                        </v-btn-toggle>
                                                    </div>
                                                </template>
                                                <template v-slot:item.keyword_count="{ item }">
                                                    <v-chip outlined class="qchip" color="primary" style="">
                                                        {{ $helpers.formatNumber(item.keyword_count) }}
                                                    </v-chip>
                                                </template>
                                                <template v-slot:item.cluster_search_volume="{ item }">
                                                    <v-chip outlined class="qchip" color="primary" style="">
                                                        {{ $helpers.formatNumber(item.cluster_search_volume) }}
                                                    </v-chip>
                                                </template>
                                                <template v-slot:item.cluster_performance="{ item }">
                                                    <div v-bind="(performance = clusterPerformanceValues[item.cluster_performance])">
                                                        <v-chip class="white--text" :color="performance.color">
                                                            <v-icon class="mr-1" size="14px">{{ performance.icon }}</v-icon> {{ performance.text }}
                                                        </v-chip>
                                                    </div>
                                                </template>
                                                <template v-for="competitor in selectedCompetitors" v-slot:[`item.domain_share.${competitor.domain}`]="{ item }">
                                                    <v-chip outlined class="qchip" :color="getShareColor(item.domain_share[competitor.domain])" style="">
                                                        {{ item.domain_share[competitor.domain] ? Math.ceil(item.domain_share[competitor.domain] * 100) : 0 }}%
                                                    </v-chip>
                                                </template>
                                                <template v-slot:item.threshold="{ item }">
                                                    <v-chip outlined class="qchip" color="primary" style="">
                                                        {{ (item.threshold * 100).toFixed(0) + "%" }}
                                                    </v-chip>
                                                </template>
                                            </v-data-table>
                                            <div v-else-if="view3 === 1">
                                                <v-slide-x-transition>
                                                    <cluster-rankings
                                                        :parent="item"
                                                        :category_id="category_id"
                                                        :selectedDates="selectedDates"
                                                        :search="search"
                                                        :project_domain="project_domain"
                                                        :fetchClusterRanking="fetchClusterRankings"
                                                        :selectedCompetitors="selectedCompetitors"></cluster-rankings
                                                ></v-slide-x-transition>
                                            </div>
                                            <div v-else>
                                                <v-slide-x-transition>
                                                    <cluster-urls
                                                        :parent="item"
                                                        :category_id="category_id"
                                                        :selectedDates="selectedDates"
                                                        :search="search"
                                                        :project_domain="project_domain"
                                                        :fetchClusterRanking="fetchClusterRankings"
                                                        :selectedCompetitors="selectedCompetitors"></cluster-urls
                                                ></v-slide-x-transition>
                                            </div>
                                        </td>
                                    </template>
                                </v-data-table>
                                <div v-else-if="view2 === 1">
                                    <v-slide-x-transition>
                                        <cluster-rankings
                                            :parent="item"
                                            :category_id="category_id"
                                            :selectedDates="selectedDates"
                                            :search="search"
                                            :project_domain="project_domain"
                                            :fetchClusterRanking="fetchClusterRankings"
                                            :selectedCompetitors="selectedCompetitors"></cluster-rankings
                                    ></v-slide-x-transition>
                                </div>
                                <div v-else>
                                    <v-slide-x-transition>
                                        <cluster-urls
                                            :parent="item"
                                            :category_id="category_id"
                                            :selectedDates="selectedDates"
                                            :search="search"
                                            :project_domain="project_domain"
                                            :fetchClusterRanking="fetchClusterRankings"
                                            :selectedCompetitors="selectedCompetitors"></cluster-urls
                                    ></v-slide-x-transition>
                                </div>
                            </td>
                        </template>
                    </v-data-table>
                    <div v-else-if="view === 1">
                        <v-slide-x-transition>
                            <cluster-rankings
                                :parent="item"
                                :category_id="category_id"
                                :selectedDates="selectedDates"
                                :search="search"
                                :project_domain="project_domain"
                                :fetchClusterRanking="fetchClusterRankings"
                                :selectedCompetitors="selectedCompetitors"></cluster-rankings
                        ></v-slide-x-transition>
                    </div>
                    <div v-else>
                        <v-slide-x-transition>
                            <cluster-urls
                                :parent="item"
                                :category_id="category_id"
                                :selectedDates="selectedDates"
                                :search="search"
                                :project_domain="project_domain"
                                :fetchClusterRanking="fetchClusterRankings"
                                :selectedCompetitors="selectedCompetitors"></cluster-urls
                        ></v-slide-x-transition>
                    </div>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    // import axios from "axios"
    import { positionColors } from "../helper/global.js"
    import ClusterRankings from "./ClusterRankings.vue"
    import ClusterUrls from "./ClusterUrls.vue"
    import FilterMenu from "./FilterMenu"
    export default {
        components: {
            ClusterRankings,
            ClusterUrls,
            FilterMenu
        },
        props: {
            category_id: {
                type: String
            },
            search: {
                type: String
            },
            project_domain: {
                type: String
            },
            selectedCompetitors: {
                type: Array,
                required: true
            },
            selectedDates: { type: Array }
        },
        data: () => ({
            clusterPerformanceValues: {
                optimized: {
                    text: "Optimized",
                    icon: "mdi-check-circle-outline",
                    color: "green darken 2"
                },
                optimizable: {
                    text: "Optimizable",
                    icon: "mdi-key-plus",
                    color: "green lighten 2"
                },
                "quick win": {
                    text: "Quick Win",
                    icon: "mdi-flash-outline",
                    color: "orange lighten 4"
                },
                update: {
                    text: "Update",
                    icon: "mdi-update",
                    color: "orange darken-4"
                },
                create: {
                    text: "No Content",
                    icon: "mdi-cancel",
                    color: "red darken-2"
                }
            },
            filters: [],
            view: 0,
            view2: 0,
            view3: 0,
            view4: 0,
            fetchClusterRankings: 0,
            tooltipMenuData: {},
            menuOpenState: {},
            showAllCategories: false,
            child3Loading: false,
            child2Loading: false,
            childLoading: false,
            expandedRows: [],
            childExpandedRows: [],
            child2ExpandedRows: [],
            sortBy: ["keyword_count"],
            sortDesc: [true],
            loading: false,
            selectedChildCluster: null,
            clusters: [], // Cluster-Daten
            totalCount: 0, // Gesamtanzahl der Cluster
            currentPage: 1, // Aktuelle Seite
            itemsPerPage: 10, // Anzahl der Elemente pro Seite
            selectedThreshold: null, // Ausgewählter Threshold
            thresholds: [0.1, 0.2, 0.3, 0.4, 0.5], // Mögliche Threshold-Filterwerte
            selectedKeywordCountRange: null, // Ausgewählter Keyword-Count-Bereich
            keywordCountRanges: [
                { text: "1-10", min: 1, max: 10 },
                { text: "11-50", min: 11, max: 50 },
                { text: "51-100", min: 51, max: 100 },
                { text: "101+", min: 101, max: Infinity }
            ],
            menu: false, // Für das Datumsfeld
            dateRange: "",
            datePickerRange: null // Bereich für Datumsauswahl
        }),
        computed: {
            filterFields() {
                let filterFields = [
                    { name: "Cluster Name", type: "text", key: "name" },
                    { name: "Domain Performance", type: "option", key: "cluster_performance", options: { values: ["update", "optimizable", "optimized", "quick win"] } },
                    { name: "Avg Search Volume", type: "number", key: "cluster_search_volume" }
                ]
                const avgSearchVolumeIndex = filterFields.findIndex((field) => field.name === "Avg Search Volume")

                // Competitor mit Domain "lutz.de"
                const competitor = this.selectedCompetitors.find((domain) => domain.domain === this.project_domain)

                if (competitor) {
                    // Neues Filterfeld für den Competitor
                    const competitorField = {
                        domain: competitor.domain,
                        name: `Domain Share - ${competitor.domain}`,
                        key: `domain_share.${competitor.domain}`,
                        type: "number"
                    }

                    // Füge das neue Filterfeld nach "Avg Search Volume" ein
                    filterFields.splice(avgSearchVolumeIndex + 1, 0, competitorField)
                }

                // Hinzufügen der restlichen Competitors
                this.selectedCompetitors.forEach((domain) => {
                    if (domain.domain !== this.project_domain) {
                        const competitorField = {
                            domain: domain.domain,
                            name: `Domain Share - ${domain.domain}`,
                            key: `domain_share.${domain.domain}`,
                            type: "number"
                        }
                        filterFields.push(competitorField)
                    }
                })
                // if (!this.categorized_project) {
                //     //
                //     filterFields.splice(1, 1)
                // }
                // if (this.show_intents) {
                //     let indexOfSvH = filterFields.findIndex((e) => e.key == "avg_search_volume")
                //     Object.keys(this.intent_icons).map((intent) => {
                //         let intentheader = {
                //             name: "Search Intent - " + intent,
                //             key: "search_intent." + intent,
                //             type: "number"
                //         }
                //         filterFields.splice(indexOfSvH + 1, 0, intentheader)
                //     })
                // }
                return filterFields
            }
        },
        methods: {
            headers(table) {
                let h = [
                    {
                        text: "Cluster Name",
                        value: "name",
                        width: "350px",
                        tooltip: "The name of the cluster is based on the strongest keyword in the cluster, determined by the keyword with the highest search volume."
                    },
                    {
                        text: "Domain Performance",
                        value: "cluster_performance",
                        align: "end",
                        tooltip: "",
                        width: "150px",
                        tooltip:
                            "The performance of your domain in this cluster. Statuses include:\n - **Dominant**: Your domain has the highest visibility in this cluster.\n - **Strong**: Your domain ranks well but isn't dominant.\n - **Weak**: Your domain has limited presence in the cluster.\n - **Absent**: Your domain does not appear for the keywords in this cluster."
                    },

                    { text: "Keyword Count", value: "keyword_count", align: "end", width: "150px", tooltip: "The total number of keywords included in this cluster." },
                    {
                        text: "Google Searches",
                        value: "cluster_search_volume",
                        align: "end",
                        tooltip: "The total search volume of all keywords in this cluster. Keywords grouped by Google with identical search volumes are recognized and aggregated appropriately.",
                        width: "150px"
                    },

                    ...this.selectedCompetitors.map((domain) => ({
                        text: domain.domain,
                        value: `domain_share.${domain.domain}`,
                        align: "end",
                        tooltip: `The visibility share of the domain in this cluster. Calculated based on the CTR (Click-Through Rate) and search volume of the keywords.`
                    })),
                    {
                        text: "SERP Overlap",
                        value: "threshold",
                        align: "end",
                        width: "250px",
                        tooltip:
                            "The SERP (Search Engine Results Page) overlap percentage for this cluster. This indicates the number of overlapping results across Google SERPs for the cluster's keywords. Higher overlap means more similar results are displayed for different keywords."
                    }
                ]
                let indexOfDomain = h.findIndex((e) => e.text == this.project_domain)
                if (indexOfDomain > -1) {
                    let targetIndex = 4
                    let removedItem = h.splice(indexOfDomain, 1)[0]
                    h.splice(targetIndex, 0, removedItem)
                }
                if (table) h[0].width = table == 1 ? "350px" : table == 2 ? "300px" : "250px"

                return h
            },
            filtersUpdated(filters) {
                console.log(filters)
                this.filters = filters
                this.fetchClusters({
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                })
            },
            async fetchClusterDetails(parent, loading, view) {
                try {
                    if (!parent) {
                        return
                    }
                    console.log(view)

                    if (view > 0) {
                        console.log(view)
                        setTimeout(() => {
                            this.fetchClusterRankings = this.fetchClusterRankings + 1
                        }, 10)
                    }
                    console.log(`Fetching children of "${parent.name}"`)
                    setTimeout(async () => {
                        if (!parent.children.length) {
                            // this.fetchClusterKeywords(parent)
                            setTimeout(() => {
                                this.fetchClusterRankings = this.fetchClusterRankings + 1
                                view = 1
                            }, 10)
                            return
                        }
                        // parent = this.expandedRows[0]
                        loading = true
                        const url = process.env.VUE_APP_MONITORING_API_URL + "/cluster/get"
                        let post_data = {
                            company_id: localStorage.company_id,
                            project_id: this.$route.params.id,
                            parent_cluster_id: parent._id,
                            limit: 1000, // Nur Root-Cluster
                            page: 1
                        }

                        let response_data = await this.$helpers.axiosPost(url, post_data)
                        let cluster = response_data.data.clusters.map((c) => {
                            c.domain_share = this.restoreDomainKeys(c.domain_share)
                            c.main_domain_share = c.domain_share[this.project_domain]
                            return c
                        })
                        console.log(cluster)
                        parent.children = cluster
                        console.log(parent)
                        loading = false
                    }, 100)
                    // parent.keywords = cluster.keywords
                } catch (error) {
                    loading = false
                    console.error(`Fehler beim Laden des Clusters ${parent._id}:`, error)
                }
            },
            getSortObject(tableOptions) {
                //timeout needed for table to change tableoptions.

                let sort = {}
                if (!tableOptions) return sort
                if (Array.isArray(tableOptions.sortBy) && tableOptions.sortBy.length) {
                    if (tableOptions.sortBy[0].startsWith("domain")) {
                        let helper = tableOptions.sortBy[0].substring(0, 12) //domain_share
                        let helper2 = tableOptions.sortBy[0].substring(13, tableOptions.sortBy[0].length) //domain_share
                        let sortString = helper + "." + helper2.replaceAll(".", "_")
                        sort[sortString] = tableOptions.sortDesc[0] ? -1 : 1
                    } else {
                        sort[tableOptions.sortBy[0]] = tableOptions.sortDesc[0] ? -1 : 1
                    }
                }
                return sort
            },
            async fetchClusters(tableOptions) {
                try {
                    this.loading = true
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/cluster/get"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.$route.params.id,
                        parent_cluster_id: null, // Nur Root-Cluster
                        page: tableOptions ? tableOptions.page : 1,
                        limit: this.itemsPerPage,
                        sort: this.getSortObject(tableOptions),
                        search: this.search || "", // Suchparameter
                        filters: this.filters,
                        category_id: this.category_id
                        // sortBy: Array.isArray(this.sortBy) && this.sortBy.length ? this.sortBy[0] : "keyword_count", // Sortierfeld
                        // sortDesc: Array.isArray(this.sortDesc) && this.sortDesc.length ? this.sortDesc[0] : true // Sortierrichtung
                    }

                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    this.clusters = response_data.data.clusters.map((c) => {
                        c.domain_share = this.restoreDomainKeys(c.domain_share)
                        c.main_domain_share = c.domain_share[this.project_domain]
                        return c
                    })
                    this.totalCount = response_data.data.totalCount
                    this.loading = false
                } catch (error) {
                    this.loading = false
                    console.error("Fehler beim Laden der Cluster:", error)
                }
            },
            restoreDomainKeys(transformedDomainShares) {
                const restored = {}
                for (const [key, value] of Object.entries(transformedDomainShares)) {
                    const originalKey = key.replace(/_/g, ".") // Ersetze Unterstriche zurück in Punkte
                    restored[originalKey] = value
                }
                return restored
            },
            getShareColor(value) {
                // Clamp value to ensure it's between 0 and 1
                // value = Math.max(0, Math.min(1, value))

                // Define color thresholds
                if (value >= 0.4) {
                    return "green darken-4" // Dark green for values >= 0.4
                } else if (value >= 0.2) {
                    return "green" // Green for values between 0.3 and 0.4
                } else if (value >= 0.1) {
                    return "yellow darken-3" // Yellow for values between 0.2 and 0.3
                } else if (value >= 0.05) {
                    return "orange darken-3" // Orange for values between 0.1 and 0.2
                } else {
                    return "red darken-3" // Red for values below 0.1
                }
            }
        },
        watch: {
            search() {
                // Fetch data when the search query changes
                this.currentPage = 1 // Reset the page to 1 when searching
                this.fetchClusters({
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                })
            },
            view(val) {
                console.log("watch:", val)

                if (val > 0) {
                    setTimeout(() => {
                        this.fetchClusterRankings = this.fetchClusterRankings + 1
                    }, 10)
                }
            },
            view2(val) {
                console.log("watch:", val)

                if (val > 0) {
                    setTimeout(() => {
                        this.fetchClusterRankings = this.fetchClusterRankings + 1
                    }, 10)
                }
            },
            view3(val) {
                console.log("watch:", val)

                if (val > 0) {
                    setTimeout(() => {
                        this.fetchClusterRankings = this.fetchClusterRankings + 1
                    }, 10)
                }
            },
            view4(val) {
                console.log("watch:", val)

                if (val > 0) {
                    setTimeout(() => {
                        this.fetchClusterRankings = this.fetchClusterRankings + 1
                    }, 10)
                }
            }
        },
        async created() {
            await this.fetchClusters()
        }
    }
</script>
<style lang="scss">
    #clustertable .qchip {
        background-color: rgb(245, 245, 245) !important;
        border-width: 1px;
    }
    .v-data-table tbody tr.v-data-table__expanded {
        background-color: #edeff1;
        > td:nth-child(2) {
            font-weight: bold;
        }
        border-bottom: 5px !important;
    }

    #\32 ndtable > div > table > tbody > tr > td:nth-child(1) {
        text-align: end !important;
    }
    #\33 ndtable > div > table > tbody > tr > td:nth-child(1) {
        text-align: end !important;
        margin-left: 50px !important;
    }
    .v-data-table tbody tr.v-data-table__expanded__content {
        box-shadow: none;
    }
    #clustertable th {
        white-space: nowrap !important;
    }
    #clustertable .v-data-footer {
        background-color: #edeff1 !important;
    }
    #clustertable table > tbody > tr > td:nth-child(3),
    #clustertable table > thead > tr > th:nth-child(3) {
        /* background: #edeff1 !important; */
        border-left: 2px solid #edeff1;
        border-right: 2px solid #edeff1;
    }
    .theme--dark #clustertable table > tbody > tr > td:nth-child(3),
    .theme--dark #clustertable table > thead > tr > th:nth-child(3) {
        /* background: #edeff1 !important; */
        border-left: 2px solid #000000;
        border-right: 2px solid #000000;
    }
</style>
