<template>
    <div>
        <v-row class="mt-2">
            <v-col xl="6" md="10" sm="12" class="ml-5">
                <div class="">
                    <div class="px-5">
                        <v-row>
                            <v-col cols="12">
                                <h2 style="font-weight: 300" class="pb-2">New Content Project</h2>
                                <p style="max-width: 800px">
                                    Use the Content Project Feature to create new or optimize existing content pieces or landing pages! Everything starts with your project title and your main keyword.
                                    Make sure to select the right location and device you are focusing.
                                </p>
                            </v-col>
                            <!-- <v-col style="display: flex" class="mx-auto" cols="3"> <img src="../../../assets/content_project.svg" width="90%" class="mx-auto" alt="" /></v-col> -->
                        </v-row>
                    </div>
                    <div class="flex"><div class="mx-auto">WHAT DO YOU WANT TO DO?</div></div>
                    <v-row class="choose">
                        <v-col>
                            <div :class="!addOwnURL ? ' quaroshadow newcontent active ml-auto' : 'ml-auto newcontent'" @click="addOwnURL = !addOwnURL">
                                <div class="choosetitle pa-auto" style="max-width: 59%">Create new Content</div>
                                <div class="chooseimage">
                                    <img v-if="!addOwnURL" src="../../../assets/newwhite.svg" width="90%" class="mx-auto" alt="" />
                                    <img v-else src="../../../assets/content_project.svg" width="90%" class="mx-auto" alt="" />
                                </div>
                            </div>
                        </v-col>
                        <v-col>
                            <div :class="addOwnURL ? 'quaroshadow optimizecontent active mr-auto' : ' optimizecontent mr-auto'" @click="addOwnURL = !addOwnURL">
                                <div class="choosetitle pa-auto"><span>Optimize existing Content</span></div>
                                <div class="chooseimage">
                                    <img src="../../../assets/opimize_primary.svg" width="90%" class="mx-auto" alt="" />
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <div class="px-5">
                        <div class="pb-4 qgreycard">
                            <div class="lbl pb-2 pb-2" style="font-size: 14px !important">
                                PROJECT TITLE
                                <v-tooltip max-width="250" color="" top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                    </template>
                                    <span>The "Project Title" field specifies the title of your content project. </span>
                                </v-tooltip>
                            </div>
                            <v-text-field @focus="showTipp('title')" type="text" rounded filled v-model="project.title" hint="e.g. Optimization: KW-Research" persistent-hint></v-text-field>
                        </div>

                        <v-row no-gutters>
                            <v-col class="cols-6">
                                <div class="pb-4 qgreycard mt-0">
                                    <div class="lbl pb-2" style="font-size: 14px !important">
                                        TOPIC
                                        <v-tooltip max-width="250" color="" top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <span>Enter any topic (phrase or keyword) you want to write about! </span>
                                        </v-tooltip>
                                    </div>
                                    <v-text-field
                                        :disabled="disabled"
                                        @focus="showTipp('main_keyword')"
                                        @blur="project.main_keyword = project.main_keyword.toLowerCase().trim()"
                                        class="mb-2"
                                        rounded
                                        filled
                                        v-model="project.main_keyword"
                                        hint="e.g. keyword research"
                                        persistent-hint></v-text-field>
                                </div>
                            </v-col>
                            <v-col class="cols-6">
                                <div class="pb-6 qgreycard mt-0">
                                    <div class="lbl pb-2" style="font-size: 14px !important">
                                        LOCATION
                                        <v-tooltip max-width="250" color="" top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <span
                                                >The "Location" field specifies the geographical location of the target audience and which search results will be analyzed for optimization purposes.
                                            </span>
                                        </v-tooltip>
                                    </div>
                                    <v-select
                                        :disabled="disabled"
                                        rounded
                                        v-model="langloc"
                                        :items="textMap"
                                        item-text="location_text"
                                        item-value="location_code"
                                        prepend-inner-icon="mdi-map-marker"
                                        return-object
                                        filled
                                        attach>
                                    </v-select>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col class="cols-6">
                                <div class="qgreycard my-0">
                                    <span class="lbl" style="font-size: 14px !important"
                                        >DEVICE
                                        <v-tooltip max-width="250" color="" top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <span
                                                >The "Device" field specifies the type of device that the target audience is likely to use to access the content. This choice can impact the analyzed
                                                competitors as well.
                                            </span>
                                        </v-tooltip>
                                    </span>
                                    <v-chip-group
                                        @change="changeDevice"
                                        class="py-0"
                                        color="orange darken-2"
                                        column
                                        show-arrows
                                        v-model="devicefilter"
                                        mandatory
                                        next-icon="mdi-chevron-right"
                                        prev-icon="mdi-chevron-left">
                                        <v-chip :disabled="disabled" v-for="(devicetype, i) in devicetypes" :key="i" filter close-icon="mdi-close-circle-outline" filter-icon="mdi-check-circle">
                                            {{ devicetype.label }}
                                        </v-chip>
                                    </v-chip-group>
                                    <!-- <div class="lbl pb-1">
                                DEVICE
                                <v-tooltip max-width="250" color="" top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                    </template>
                                    <span
                                        >The "Device" field specifies the type of device that the target audience is likely to use to access the content. This choice can impact the analyzed
                                        competitors as well.</span
                                    >
                                </v-tooltip>
                            </div>
                            <v-radio-group :disabled="disabled" class="mt-0" v-model="project.device" row>
                                <v-radio label="Mobile (recommended)" value="mobile"></v-radio>
                                <v-radio label="Desktop" value="desktop"></v-radio>
                            </v-radio-group> -->
                                </div>
                            </v-col>
                            <v-col class="cols-6">
                                <div class="qgreycard my-0">
                                    <span class="lbl" style="font-size: 14px !important"
                                        >COMPETITORS
                                        <v-tooltip max-width="250" color="" top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <span>The "Competitors" field specifies which competitors will be selected as a reference for your content porject. </span>
                                        </v-tooltip>
                                    </span>
                                    <v-chip-group
                                        @change="changeDepth"
                                        class="py-0"
                                        color="cyan darken-2"
                                        column
                                        show-arrows
                                        v-model="resultFilter"
                                        mandatory
                                        next-icon="mdi-chevron-right"
                                        prev-icon="mdi-chevron-left">
                                        <v-chip :disabled="disabled" v-for="(resultChip, i) in resultChips" :key="i" filter close-icon="mdi-close-circle-outline" filter-icon="mdi-check-circle">
                                            {{ resultChip.label }}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </v-col>
                        </v-row>

                        <!-- <div style="display: flex">
                            <v-btn outlined color="primary" class="pl-1 ml-auto" @click="addOwnURL = !addOwnURL">
                                <v-icon class="pr-2">{{ addOwnURL ? "mdi-checkbox-marked-outline" : "mdi-checkbox-blank-outline" }}</v-icon>
                                add own URl for comparison
                            </v-btn>
                        </div> -->

                        <v-slide-x-transition>
                            <div v-if="addOwnURL" class="qgreycard">
                                <div class="lbl pb-1">WEBSITE URL</div>
                                <v-text-field class="my-2" rounded filled v-model="project.url" hint="e.g. https://www.quaro.io/keyword-recherche" persistent-hint></v-text-field>
                            </div>
                        </v-slide-x-transition>

                        <v-row v-if="!edit">
                            <v-col class="pl-5">
                                <v-switch v-model="project.setup.fastmode" class="pb-2" color="cyan darken-2" hide-details label="Fast Mode"></v-switch>
                                <v-tooltip max-width="250" color="" top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" x-small outlined color="cyan darken-2">What is Fast Mode?</v-btn>
                                    </template>
                                    <strong style="font-size: 18px">Fast Mode</strong>
                                    <p>Speed up the Process and skip most of the setup steps to get directly to the Editor, Briefing or Analysis.</p>
                                    <p>
                                        <strong>Note: You can change the settings later on.</strong>
                                    </p>
                                </v-tooltip>
                                <!-- <v-dialog width="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-on="on" x-small outlined color="cyan darken-2">What is Fast Mode?</v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title class="text-h5 grey lighten-2"> Fast Mode </v-card-title>

                                        <v-card-text>
                                          Speed up the Process and skip most of the setup steps to get directly to the Editor, Briefing or Analysis
                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" text @click="dialog = false"> I accept </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog> -->
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        props: {
            project: Object,
            edit: Boolean
        },
        data() {
            return {
                devicetypes: [
                    { selected: true, label: "Mobile" },
                    { selected: false, label: "Desktop" }
                ],
                devicefilter: [0],
                resultFilter: 1,
                resultChips: [
                    { depth: 5, label: "Top 5" },
                    { depth: 10, label: "Top 10" },
                    { depth: 20, label: "Top 20" },
                    { depth: 30, label: "Top 30" }
                ],
                chooseoption: 1,
                addOwnURL: false,
                // langloc: { language_code: "de", location_code: "2276" },
                langloc_items: [
                    { language_code: "de", location_code: "2276" },
                    { language_code: "de", location_code: "2756" },
                    { language_code: "de", location_code: "2040" },
                    { language_code: "en", location_code: "2826" },
                    { language_code: "en", location_code: "2840" },
                    { language_code: "it", location_code: "2380" }
                ],
                textMap: [
                    { location_text: "Germany", language_code: "de", location_code: "2276" },
                    { location_text: "Switzerland (de)", language_code: "de", location_code: "2756" },
                    { location_text: "Austria (de)", language_code: "de", location_code: "2040" },
                    { location_text: "United Kingdom", language_code: "en", location_code: "2826" },
                    { location_text: "United States", language_code: "en", location_code: "2840" },
                    { location_text: "Italy", language_code: "it", location_code: "2380" }
                ],
                tipps: [
                    {
                        id: "title",
                        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui expedita a ratione eius, dignissimos veniam, cumque tempora sapiente harum praesentium voluptates tenetur repellat dolores unde atque animi, culpa quae minima!"
                    },
                    {
                        id: "main_keyword",
                        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui expedita a ratione eius, dignissimos veniam, cumque tempora sapiente harum praesentium voluptates tenetur repellat dolores unde atque animi, culpa quae minima!"
                    },
                    {
                        id: "device",
                        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui expedita a ratione eius, dignissimos veniam, cumque tempora sapiente harum praesentium voluptates tenetur repellat dolores unde atque animi, culpa quae minima!"
                    }
                ],
                visibleTipp: null
            }
        },
        methods: {
            changeDevice(val) {
                this.project.device = this.devicetypes[val].label
            },
            changeDepth(val) {
                this.project.setup.depth = this.resultChips[val]
            },
            showTipp(tippID) {
                if (!this.visibleTipp) {
                    this.visibleTipp = this.tipps.find((tipp) => tipp.id == tippID)
                } else {
                    this.visibleTipp = null
                    setTimeout(() => {
                        this.visibleTipp = this.tipps.find((tipp) => tipp.id == tippID)
                    }, 500)
                }
            }
        },
        computed: {
            disabled() {
                console.log(this.project)
                if (this.project) {
                    console.log(this.project.setup)
                    return this.project.setup.finished || this.project.setup.step >= 2
                }
                return true
            },
            langloc: {
                get() {
                    return this.project.langloc
                },
                set(val) {
                    this.project.langloc = this.langloc_items.find((entry) => entry.location_code == val.location_code)
                }
            }
            // devicefilter: {
            //     get() {
            //         return this.project.device
            //     },
            //     set(val) {
            //         this.project.device = this.devicetypes[this.devicefilter].label
            //     }
            // },
            // resultFilter: {
            //     get() {
            //         return this.project.depth
            //     },
            //     set(val) {
            //         this.project.depth = this.resultChips[this.resultFilter]
            //     }
            // }
        },
        mounted() {
            this.addOwnURL = this.project.url ? true : false
        },
        watch: {
            project() {
                this.addOwnURL = this.project.url ? true : false
            }
        }
    }
</script>

<style lang="scss">
    .infocard {
        height: 100%;
        background-color: rgb(199, 199, 199);
        border-radius: 15px;
    }
    .lbl {
        font-size: 14px !important;
        font-weight: 600;
    }
    .formarea {
        /* border-radius: 10px;
        padding: 5px 20px;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); */
        /* background: linear-gradient(
            90deg,
            var(--v-background_gradient_1-base) 0%,
            var(--v-background_gradient_2-base) 26%,
            var(--v-background_gradient_3-base) 50%,
            var(--v-background_gradient_4-base) 75%,
            var(--v-background_gradient_5-base) 100%
        ); */
        background: #fafbfa;
    }
    .tippicon {
        display: flex;
        height: 100%;
    }
    .tipp {
        display: flex;
        height: 100%;
    }
    .tooltipicon {
        margin-bottom: 3px;
        margin-left: 1px;
    }
    .choose > div > div {
        max-width: 300px;
        display: flex;
        // margin: auto;
        padding: 0px 30px;
        background-color: #f6f6f6;
        border-radius: 10px;
        &:hover {
            background-color: #dddcdc;
            cursor: pointer;
        }
        &.active {
            background-color: var(--v-primary-base) !important;
            color: white;
        }
    }
    .theme--dark .choose > div > div {
        background-color: #1c1c1c;
    }
    .chooseimage {
        max-width: 40%;
        padding: 10px;
    }
    .choosetitle {
        display: flex;
        margin: auto;
    }
</style>
