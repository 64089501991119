<template>
    <v-container>
        <v-expansion-panels>
            <v-expansion-panel>
                <!-- Panel Header -->
                <v-expansion-panel-header>
                    <v-row align="center" no-gutters>
                        <v-icon color="primary" class="mr-3">mdi-wallet-outline</v-icon>
                        <span class="text-h6 font-weight-bold">Credits</span>
                    </v-row>
                </v-expansion-panel-header>

                <!-- Panel Content -->
                <v-expansion-panel-content>
                    <v-card outlined>
                        <v-card-text>
                            <!-- Research Suite Credits -->
                            <div class="credit-section mb-4">
                                <v-chip label color="blue lighten-4" class="mb-2 font-weight-bold" text-color="blue darken-3"> Research Suite </v-chip>
                                <div class="relative">
                                    <v-progress-linear :value="(researchCredits.remaining / researchCredits.total) * 100" :color="getColor(researchCredits)" height="20" class="progress-bar"
                                        ><template v-slot:default="{ value }">
                                            <strong>{{ researchCredits.remaining }} / {{ researchCredits.total }}</strong>
                                        </template></v-progress-linear
                                    >
                                    <span class="credit-text"> {{ researchCredits.remaining }} / {{ researchCredits.total }} </span>
                                </div>
                            </div>

                            <!-- Content Suite Credits -->
                            <div :class="show_ms ? 'credit-section mb-4' : 'credit-section'">
                                <v-chip label color="green lighten-4" class="mb-2 font-weight-bold" text-color="green darken-3"> Content Suite </v-chip>
                                <div class="relative">
                                    <v-progress-linear :value="(contentCredits.remaining / contentCredits.total) * 100" :color="getColor(contentCredits)" height="20" class="progress-bar"
                                        ><template v-slot:default="{ value }">
                                            <strong>{{ contentCredits.remaining }} / {{ contentCredits.total }}</strong>
                                        </template></v-progress-linear
                                    >
                                    <!-- <span class="credit-text"> {{ contentCredits.remaining }} / {{ contentCredits.total }} </span> -->
                                </div>
                            </div>

                            <!-- Monitoring Credits -->
                            <div class="credit-section">
                                <v-chip label color="red lighten-4" class="mb-2 font-weight-bold" text-color="red darken-3"> Monitoring </v-chip>
                                <div class="relative">
                                    <v-progress-linear :value="(monitoringCredits.remaining / monitoringCredits.total) * 100" :color="getColor(monitoringCredits)" height="20" class="progress-bar"
                                        ><template v-slot:default="{ value }">
                                            <strong>{{ monitoringCredits.remaining }} / {{ monitoringCredits.total }}</strong>
                                        </template></v-progress-linear
                                    >
                                    <!-- <span class="credit-text"> {{ monitoringCredits.remaining }} / {{ monitoringCredits.total }} </span> -->
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                researchCredits: {
                    total: 1000,
                    remaining: 600
                },
                contentCredits: {
                    total: 500,
                    remaining: 200
                },
                monitoringCredits: {
                    total: 300,
                    remaining: 50
                }
            }
        },
        props: {
            show_all: Boolean,
            show_rs: Boolean,
            show_ms: Boolean,
            show_cs: Boolean
        },
        methods: {
            getColor(credit) {
                const percentage = (credit.remaining / credit.total) * 100
                if (percentage > 75) return "red"
                else if (percentage > 50) return "orange"
                return "green"
            }
        }
    }
</script>

<style scoped>
    .credit-section {
        position: relative;
    }

    .relative {
        position: relative;
    }

    .progress-bar {
        width: 100%;
    }

    .credit-text {
        position: absolute;
        top: -5px;
        right: 10px;
        font-size: 14px;
        font-weight: bold;
        color: black;
        z-index: 1;
    }
</style>
