<template>
    <div id="contentcontainter" class="standard-mode">
        <v-dialog v-model="videodialog" max-width="800px">
            <div class="video-container">
                <iframe
                    style="border-radius: 10px"
                    width="560"
                    height="315"
                    :src="videoUrl"
                    title="Demo Video Content Suite"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </v-dialog>

        <div style="max-width: 1800px; margin: auto">
            <v-row class="mt-5">
                <v-col>
                    <v-card style="border-top: 4px solid rgb(1 158 133) !important" max-width="450px" class="mr-auto rounded_card quaroshadow transparent">
                        <v-card-text style="padding-top: 5px; padding-bottom: 5px">
                            <v-row>
                                <v-col class="">
                                    <h1 class="my-2" style="font-family: 'CalSans', sans-serif">
                                        {{ titleUsername }}
                                        <!-- <v-chip x-small color="red" class="white--text ml-1 px-2"> new feature</v-chip> -->
                                    </h1>

                                    <p class="mb-3 mt-2" style="font-size: 16px; font-family: 'CalSans', sans-serif">{{ bannertext.firstp }}</p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- <v-col sm="12" md="6" xl="4" class="mb-2">
                    <v-slide-x-transition>
                        <v-card class="px-5 py-3 rounded_card quaroshadow" id="kwcountCard">
                            <v-row no-gutters>
                                <v-sheet color="primary" max-height="50" class="pa-12 rounded_card" dark top id="searchsheet" elevation="6">
                                    <div class="headingsheet2">
                                        <v-icon id="sheeticon" :light="this.$vuetify.theme.dark ? true : false" large>mdi-database</v-icon>
                                    </div>
                                </v-sheet>
                                <div class="ml-auto text-right">
                                    <div class="body-3 grey--text font-weight-light">Saved Keywords</div>
                                    <div>541.518</div>
                                </div>
                            </v-row>
                            <v-col cols="12" class="px-0 pb-1">
                                <v-divider />
                            </v-col>
                            <v-icon size="16" class="ml-2 mr-1"> mdi-clock </v-icon>
                            <span class="caption grey--text font-weight-light"> Updated on {{ updatedDate }}</span>
                        </v-card>
                    </v-slide-x-transition>
                </v-col>
                <v-col sm="12" md="6" xl="4" class="mb-2">
                    <v-slide-x-transition>
                        <v-card class="px-5 py-3 rounded_card quaroshadow" id="kwcountCard">
                            <v-row no-gutters>
                                <v-sheet color="primary" max-height="50" class="pa-12 rounded_card" dark top id="searchsheet" elevation="6">
                                    <div class="headingsheet2">
                                        <v-icon id="sheeticon" :light="this.$vuetify.theme.dark ? true : false" large>mdi-database</v-icon>
                                    </div>
                                </v-sheet>
                                <div class="ml-auto text-right">
                                    <div class="body-3 grey--text font-weight-light">Content Projects</div>
                                    <div>13</div>
                                </div>
                            </v-row>
                            <v-col cols="12" class="px-0 pb-1">
                                <v-divider />
                            </v-col>
                            <v-icon size="16" class="ml-2 mr-1"> mdi-clock </v-icon>
                            <span class="caption grey--text font-weight-light"> Updated on {{ updatedDate }}</span>
                        </v-card>
                    </v-slide-x-transition>
                </v-col>
                <v-col sm="12" md="6" xl="4" class="mb-2">
                    <v-slide-x-transition>
                        <v-card class="px-5 py-3 rounded_card quaroshadow" id="kwcountCard">
                            <v-row no-gutters>
                                <v-sheet color="primary" max-height="50" class="pa-12 rounded_card" dark top id="searchsheet" elevation="6">
                                    <div class="headingsheet2">
                                        <v-icon id="sheeticon" :light="this.$vuetify.theme.dark ? true : false" large>mdi-database</v-icon>
                                    </div>
                                </v-sheet>
                                <div class="ml-auto text-right">
                                    <div class="body-3 grey--text font-weight-light">Tracked Keywords</div>
                                    <div>24.000</div>
                                </div>
                            </v-row>
                            <v-col cols="12" class="px-0 pb-1">
                                <v-divider />
                            </v-col>
                            <v-icon size="16" class="ml-2 mr-1"> mdi-clock </v-icon>
                            <span class="caption grey--text font-weight-light"> Updated on {{ updatedDate }}</span>
                        </v-card>
                    </v-slide-x-transition>
                </v-col> -->
            </v-row>
            <v-row class="mt-5">
                <v-col xs="12" sm="12" md="6" lg="4">
                    <v-card :disabled="!$store.state.research_suite" max-width="600px" height="100%" class="mr-auto rounded_card quaroshadow transparent" style="overflow: hidden">
                        <v-overlay style="z-index: 9" :value="isResearchDisabled" absolute>
                            <div class="text-center">
                                <v-btn rounded small color="primary" class="white--text" @click="$router.push('/pricing')"> Upgrade Plan </v-btn>
                            </div>
                        </v-overlay>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col md="8" sm="8">
                                    <p style="line-height: 1; color: #019e86; padding-left: 2px; margin-bottom: 6px; font-family: 'CalSans', sans-serif; font-weight: bold; font-size: 16px">
                                        Research Suite
                                    </p>
                                    <p class="homecardtitle" style="line-height: 1; color: black; font-family: 'CalSans', sans-serif; font-weight: bold; font-size: 26px">
                                        Research Keywords
                                        <!-- <v-chip x-small color="red" class="white--text ml-1 px-2"> new feature</v-chip> -->
                                    </p>

                                    <p class="mb-3">{{ researchText.firstp }}</p>
                                    <div style="display: inline">
                                        <v-btn rounded color="grey" class="pl-2 mr-2" small @click="$router.push('/ideas')">
                                            <v-icon small class="mr-1">mdi-start</v-icon>
                                            Let´s go</v-btn
                                        >
                                        <v-btn
                                            outlined
                                            rounded
                                            color="grey"
                                            class="pl-2"
                                            small
                                            @click="
                                                videoUrl = videoUrls[1]
                                                videodialog = true
                                            ">
                                            <v-icon small class="mr-1">mdi-video</v-icon>
                                            tutorial</v-btn
                                        >
                                    </div>
                                </v-col>
                                <v-col md="4" sm="4" style="display: flex">
                                    <img style="transform: scaleX(-1)" width="150" class="mx-auto" src="../assets/undraw_searching.svg" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col xs="12" sm="12" md="6" lg="4">
                    <v-card
                        :disabled="!$store.state.content_suite || ($store.state.company_status_content_suite != 'active' && $store.state.company_status_content_suite != 'testing')"
                        max-width="600px"
                        height="100%"
                        class="mr-auto rounded_card quaroshadow transparent"
                        style="overflow: hidden">
                        <v-overlay :value="isContentDisabled" absolute>
                            <div class="text-center">
                                <v-btn rounded small color="primary" class="white--text" @click="$router.push('/pricing')"> Upgrade Plan </v-btn>
                            </div>
                        </v-overlay>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col md="8" sm="8">
                                    <p style="line-height: 1; color: #019e86; padding-left: 2px; margin-bottom: 6px; font-family: 'CalSans', sans-serif; font-weight: bold; font-size: 16px">
                                        Content Suite
                                    </p>
                                    <p class="homecardtitle" style="line-height: 1; color: black; font-family: 'CalSans', sans-serif; font-weight: bold; font-size: 26px">
                                        Create Content
                                        <!-- <v-chip x-small color="red" class="white--text ml-1 px-2"> new feature</v-chip> -->
                                    </p>

                                    <p class="mb-3">{{ contenttext.firstp }}</p>

                                    <div style="display: inline">
                                        <v-btn rounded color="grey" class="pl-2 mr-2" small @click="$router.push('/content-project/setup')">
                                            <v-icon small class="mr-1">mdi-start</v-icon>
                                            Let´s go</v-btn
                                        ><v-btn
                                            outlined
                                            rounded
                                            color="grey"
                                            class="pl-2"
                                            small
                                            @click="
                                                videoUrl = videoUrls[0]
                                                videodialog = true
                                            ">
                                            <v-icon small class="mr-1">mdi-video</v-icon>
                                            tutorial</v-btn
                                        >
                                    </div>
                                </v-col>
                                <v-col md="4" sm="4" style="display: flex">
                                    <img style="transform: scaleX(-1)" width="150" class="mx-auto" src="../assets/undraw_typewriter.svg" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col xs="12" sm="12" md="6" lg="4">
                    <v-card
                        :disabled="!$store.state.monitoring_suite || ($store.state.company_status_monitoring_suite != 'active' && $store.state.company_status_monitoring_suite != 'testing')"
                        max-width="600px"
                        height="100%"
                        class="mr-auto rounded_card quaroshadow transparent"
                        style="overflow: hidden">
                        <v-overlay :value="isMonitoringDisabled" absolute>
                            <div class="text-center">
                                <v-btn rounded small color="primary" @click="$router.push('/pricing')"> Upgrade Plan </v-btn>
                            </div>
                        </v-overlay>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col md="8" sm="8">
                                    <p style="line-height: 1; color: #019e86; padding-left: 2px; margin-bottom: 6px; font-family: 'CalSans', sans-serif; font-weight: bold; font-size: 16px">
                                        Monitoring Suite
                                    </p>
                                    <p class="homecardtitle" style="line-height: 1; color: black; font-family: 'CalSans', sans-serif; font-weight: bold; font-size: 26px">
                                        Rank Tracking
                                        <!-- <v-chip x-small color="red" class="white--text ml-1 px-2"> new feature</v-chip> -->
                                    </p>

                                    <p class="mb-3">{{ monitoringtext.firstp }}</p>

                                    <div style="display: inline">
                                        <v-btn rounded color="grey" class="pl-2 mr-2" small @click="$router.push('/monitoring/setup')">
                                            <v-icon small class="mr-1">mdi-start</v-icon>
                                            Let´s go</v-btn
                                        >
                                        <!-- <v-btn disabled outlined rounded color="grey" class="pl-2" small @click="$router.push('/monitoring/setup')">
                                            <v-icon small class="mr-1">mdi-video</v-icon>
                                            tutorial</v-btn
                                        > -->
                                    </div>
                                </v-col>
                                <v-col md="4" sm="4" style="display: flex">
                                    <img style="transform: scaleX(-1)" width="150" class="mx-auto" src="../assets/undraw_setup_analytics.svg" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    </div>
</template>

<script>
    // @ is an alias to /src
    // import HelloWorld from "@/components/HelloWorld.vue"

    export default {
        name: "Home",
        components: {},
        data() {
            return {
                videodialog: false,
                videoUrl: "https://www.youtube.com/embed/6J1xuj3LqPs",
                videoUrls: ["https://www.youtube.com/embed/6J1xuj3LqPs", "https://www.youtube.com/embed/JIwOqGzpBRU"],
                bannertext: {
                    title: "Hi",
                    firstp: "welcome back! What do you want to do today?"
                },
                researchText: {
                    title: "Research Keywords",
                    subtitle: "Research Suite",
                    firstp: "Find out what people are searching for on Google or other platforms!"
                },
                contenttext: {
                    title: "Create Content",
                    subtitle: "Content Suite",
                    firstp: "Create Content Briefings in seconds or write content with the SEO AI Editor!"
                },
                monitoringtext: {
                    title: "Rank Tracking",
                    subtitle: "Monitoring Suite",
                    firstp: "Monitor your rankings and keep a watch on your competitors in Google Search results!"
                }
            }
        },
        computed: {
            isMonitoringDisabled() {
                return this.$store.state.company_status_monitoring_suite != "active" && this.$store.state.company_status_monitoring_suite != "testing"
            },
            isResearchDisabled() {
                return this.$store.state.company_status != "active" && this.$store.state.company_status != "testing"
            },
            isContentDisabled() {
                return this.$store.state.company_status_content_suite != "active" && this.$store.state.company_status_content_suite != "testing"
            },
            titleUsername() {
                try {
                    console.log("username", localStorage.getItem("username"))
                    if (localStorage.getItem("username").length > 0) {
                        let username = this.CryptoJS.AES.decrypt(localStorage.getItem("username"), process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8).split(" ")[0]
                        return this.bannertext.title + " " + username + ","
                    } else {
                        return this.bannertext.title + ","
                    }
                } catch (error) {
                    return this.bannertext.title + ","
                }
            }
        }
    }
</script>
<style>
    #searchsheet {
        margin-top: -30px;
        margin-left: 5px;
        width: 30px;
        height: 30px;
    }
    .transparent {
        /* background-color: #ffffffa8; */
    }
    .theme--dark .homecardtitle {
        color: white !important;
    }
    .video-container {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 Ratio */
        height: 0;
        overflow: hidden;
    }

    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>
