var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"id":"cluster_rankings_table","hide-default-headers":"","headers":_vm.urlHeader,"items":_vm.urls},scopedSlots:_vm._u([_vm._l((_vm.urlHeader),function(header_entry,index){return {key:("header." + (header_entry.value)),fn:function(ref){
var header = ref.header;
return [_c('span',{key:index},[_c('span',{style:({ width: header.width })},[_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":"","disabled":header.tooltip ? false : true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"white-space":"nowrap","text-transform":"uppercase","font-weight":"500"}},on),[_vm._v(" "+_vm._s(header.text)+" "),(header.tooltip)?_c('v-icon',{staticClass:"questionIcon",attrs:{"x-small":""}},[_vm._v("mdi-help-circle-outline")]):_vm._e()],1)]}}],null,true)},[_c('span',[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-help-circle-outline")])],1)]),_vm._v(" "+_vm._s(header.tooltip)+" ")],1)])],1)])]}}}),{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-5"},[_c('span',{staticClass:"urllink",on:{"click":function($event){return _vm.openUrl(item.url)}}},[_vm._v(_vm._s(item.url))])])]}},{key:"item.estimatedTraffic",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"qchip",attrs:{"outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$helpers.formatNumber(item.estimatedTraffic))+" ")])]}},{key:"item.averagePosition",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"qchip",attrs:{"outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$helpers.formatNumber(item.averagePosition))+" ")])]}},{key:"item.top10Count",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"qchip",attrs:{"outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$helpers.formatNumber(item.top10Count))+" ")])]}},{key:"item.top50Count",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"qchip",attrs:{"outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$helpers.formatNumber(item.top50Count))+" ")])]}},{key:"item.top100Count",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"qchip",attrs:{"outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$helpers.formatNumber(item.top100Count))+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }