<template>
    <div>
        <div class="v-card__title sheet-card-title pt-2 pb-1" id="tabletitle" v-if="!$store.state.user_settings.advancedMode">
            {{ title }}
            <span v-if="cardData.searchPhrases" class="pr-5 sheet-card-title">
                <span class="kursiv ml-1"> "{{ cardData.searchPhrases[0] }}"</span></span
            >
        </div>
        <v-row no-gutters class="sheet-card-title v-card__subtitle pt-3 pb-2" id="card__subtitle">
            <span v-if="selected.length > 0">
                <v-chip class="white--text" color="primary" small> {{ selected.length }} of {{ keywordCount }} selected</v-chip></span
            >
            <span v-else class="mt-auto">
                <v-chip small>{{ keywordCount }} keywords</v-chip></span
            >
            <v-text-field
                v-model="search"
                color="primary"
                append-icon="mdi-magnify"
                class="adv_mode_searchbar"
                label="Search table..."
                hide-details
                filled
                dense
                rounded
                single-line
                clearable
                clear-icon="mdi-close"
                @click:clear="
                    {
                        search = ''
                    }
                "
                ref="searchfield"
                style="width: 40%" />
        </v-row>

        <v-row no-gutters id="table_actions_trends" class="px-4 pb-1 pt-0">
            <v-col>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="selectAll"
                            :outlined="$store.state.user_settings.advancedMode ? false : true"
                            :small="$store.state.user_settings.advancedMode ? false : true"
                            :icon="$store.state.user_settings.advancedMode ? true : false"
                            color="primary"
                            class="mb-1 mr-1"
                            id="select_all_btn"
                            v-on="on">
                            <v-icon color="primary" dark>{{ !selected.length ? "mdi-checkbox-multiple-marked-outline" : "mdi-checkbox-multiple-blank-outline" }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ !selected.length ? "Select all" : "Unselect all" }}</span>
                </v-tooltip>

                <v-tooltip top :disabled="!$store.state.user_settings.advancedMode">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            id="add_clipboard_btn"
                            v-if="mountedOn != 'SharedResearch'"
                            class="mr-1 mb-1"
                            :outlined="$store.state.user_settings.advancedMode ? false : true"
                            :small="$store.state.user_settings.advancedMode ? false : true"
                            :icon="$store.state.user_settings.advancedMode ? true : false"
                            color="primary"
                            @click="addToClipboard">
                            <span v-if="!$store.state.user_settings.advancedMode"> add to clipboard</span>
                            <v-icon :class="!$store.state.user_settings.advancedMode ? 'ml-1' : ''">mdi-text-box-plus-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>add to clipboard</span>
                </v-tooltip>

                <v-tooltip top :disabled="!$store.state.user_settings.advancedMode">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            class="mr-1 mb-1"
                            id="add_chart_btn"
                            :outlined="$store.state.user_settings.advancedMode ? false : true"
                            :small="$store.state.user_settings.advancedMode ? false : true"
                            :icon="$store.state.user_settings.advancedMode ? true : false"
                            color="primary"
                            @click="addToChart(null)"
                            :disabled="selected.length > 11">
                            <span v-if="!$store.state.user_settings.advancedMode"> add to chart</span>
                            <v-icon :class="!$store.state.user_settings.advancedMode ? 'ml-1' : ''">mdi-chart-timeline-variant</v-icon>
                        </v-btn>
                    </template>
                    <span>add to chart</span>
                </v-tooltip>

                <v-tooltip top :disabled="!$store.state.user_settings.advancedMode">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            v-if="$store.state.user_settings.advancedMode"
                            class="mr-1 mb-1"
                            :outlined="$store.state.user_settings.advancedMode ? false : true"
                            :small="$store.state.user_settings.advancedMode ? false : true"
                            :icon="$store.state.user_settings.advancedMode ? true : false"
                            color="primary"
                            @click="$emit('fetchSERP', selected)"
                            :disabled="selected.length > 1">
                            <v-icon :class="!$store.state.user_settings.advancedMode ? 'ml-1' : ''">mdi-feature-search-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>load SERP</span>
                </v-tooltip>
                <v-tooltip top :disabled="!$store.state.user_settings.advancedMode">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            class="mr-1 mb-1"
                            id="add_chart_btn"
                            :outlined="$store.state.user_settings.advancedMode ? false : true"
                            :small="$store.state.user_settings.advancedMode ? false : true"
                            :icon="$store.state.user_settings.advancedMode ? true : false"
                            color="primary"
                            @click="$emit('exportTrends', selected.length ? selected : rows, headers)">
                            <span v-if="!$store.state.user_settings.advancedMode">export</span>
                            <v-icon :class="!$store.state.user_settings.advancedMode ? 'ml-1' : ''">mdi-cloud-download</v-icon>
                        </v-btn>
                    </template>
                    <span>export</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-layout fluid v-resize="onResize" child-flex>
            <v-data-table
                id="trend_table"
                ref="trend_table"
                :height="tableHeightTrend"
                :search.sync="search"
                v-model="selected"
                show-select
                fixed-header
                :headers="headers"
                :items="rows"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                item-key="keyword"
                class="elevation-0"
                :footer-props="{ 'items-per-page-options': [50, 100, 250] }"
                :items-per-page="25"
                :custom-sort="customSort">
                <!-- ----------- -->
                <!-- header slot -->
                <!-- ----------- -->
                <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
                    <v-tooltip v-if="h.tooltip" top v-bind:key="h.value">
                        <template v-slot:activator="{ on }">
                            <span v-on="on">{{ h.text }} <v-icon x-small>mdi-help</v-icon></span>
                        </template>
                        <span>{{ h.tooltip }}</span>
                    </v-tooltip>
                    <span v-else v-bind:key="h.value">{{ h.text }}</span>
                </template>
                <!-- ------- -->
                <!-- keyword -->
                <!-- ------- -->
                <template v-slot:item.keyword="{ item }" class="keyword_td">
                    <!-- <span class="mr-5">{{ item.keyword }}</span>
                    <span v-if="mountedOn != 'Research'" class="savedicon_wrapper">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="savedicon" small :color="item.lists ? 'primary' : 'white'">mdi-content-save</v-icon>
                            </template>
                            <span v-if="item.lists">
                                <div class="saved-in">Saved in:</div>
                                <ul>
                                    <li v-for="(list, index) in item.lists" :key="index">{{ list }}</li>
                                </ul>
                            </span>
                            <span v-else><div class="saved-in">Not saved</div></span>
                        </v-tooltip>
                    </span> -->
                    <div class="kw_table_td">
                        <div class="mr-1">
                            <span> {{ item.keyword }}</span>
                        </div>
                        <div class="ml-auto" style="display: flex">
                            <div v-if="mountedOn != 'Research'" class="savedicon_wrapper">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" class="savedicon" small :color="item.lists ? 'primary' : item.in_clipboard ? '#e6a940' : 'white'">mdi-content-save</v-icon>
                                    </template>
                                    <span v-if="item.lists">
                                        <div class="saved-in">Saved in:</div>
                                        <ul>
                                            <li v-for="(list, index) in item.lists" :key="index">{{ list }}</li>
                                        </ul>
                                    </span>
                                    <span v-else-if="item.in_clipboard"><div class="saved-in">in clipboard</div></span>
                                    <span v-else><div class="saved-in">Not saved</div></span>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- ----- -->
                <!-- avgsv -->
                <!-- ----- -->
                <template v-slot:item.avgsv="{ item }">
                    <v-chip color="primary">{{ $helpers.formatNumber(item.avgsv) }}</v-chip>
                </template>

                <!-- ----- -->
                <!-- trend -->
                <!-- ----- -->

                <template v-slot:item.trend="{ item }">
                    <v-row no-gutters v-bind="(sparklineData = { data: item.trend.slice(-12) })">
                        <v-col class="d-flex" style="position: relative">
                            <v-sparkline
                                :value="sparklineData.data"
                                color="primary"
                                padding="2"
                                style="max-width: 68px; max-height: 45px"
                                smooth="20"
                                gradient-direction="top"
                                :fill="false"></v-sparkline>
                            <v-sparkline
                                :value="sparklineData.data"
                                style="max-width: 68px; max-height: 45px; position: absolute"
                                :gradient="gradient"
                                padding="2"
                                smooth="20"
                                gradient-direction="top"
                                :fill="true"></v-sparkline> </v-col
                    ></v-row>
                    <!-- <v-sparkline class="ma-auto" :value="item.trend" :gradient="gradient" smooth="1" padding="6" line-width="6" stroke-linecap="round" gradient-direction="top" auto-draw></v-sparkline> -->
                </template>
                <!-- ------------- -->
                <!-- sumLatestYear -->
                <!-- ------------- -->
                <template v-slot:item.sumLatestYear="{ item }">
                    <v-chip>{{ $helpers.formatNumber(item.sumLatestYear) }}</v-chip>
                </template>
                <!-- ----------- -->
                <!-- sumLastYear -->
                <!-- ----------- -->
                <template v-slot:item.sumLastYear="{ item }">
                    <v-chip>{{ $helpers.formatNumber(item.sumLastYear) }}</v-chip>
                </template>
                <!-- ------- -->
                <!-- percent -->
                <!-- ------- -->
                <template v-slot:item.percent="{ item }">
                    <v-chip dark :color="item.percent > 0 ? 'green' : 'red'">{{ $helpers.formatNumber(item.percent) }} <span v-if="item.percent != Infinity">% </span> </v-chip>
                </template>
                <template v-slot:item.diff="{ item }">
                    <v-chip dark :color="item.diff > 0 ? 'green' : 'red'">{{ $helpers.formatNumber(item.diff) }} </v-chip>
                </template>
                <!-- --------------- -->
                <!-- latestYearMonth -->
                <!-- --------------- -->
                <template v-slot:item.latestYearMonth="{ item }">
                    <v-chip>{{ $helpers.formatNumber(item.latestYearMonth.count) }}</v-chip>
                </template>
                <!-- ------------- -->
                <!-- lastYearMonth -->
                <!-- ------------- -->
                <template v-slot:item.lastYearMonth="{ item }">
                    <v-chip>{{ $helpers.formatNumber(item.lastYearMonth.count) }}</v-chip>
                </template>
                <!-- ------- -->
                <!-- percent -->
                <!-- ------- -->
                <template v-slot:item.monthPercentChange="{ item }">
                    <v-chip dark :color="item.monthPercentChange > 0 ? 'green' : 'red'">{{ $helpers.formatNumber(item.monthPercentChange) }}<span v-if="item.percent != Infinity">% </span></v-chip>
                </template>
                <template v-slot:item.monthChange="{ item }">
                    <v-chip dark :color="item.monthChange > 0 ? 'green' : 'red'">{{ $helpers.formatNumber(item.monthChange) }}</v-chip>
                </template>

                <template v-slot:item.movingavg_short="{ item }">
                    <!-- <v-chip dark :color="item.monthPercentChange > 0 ? 'green' : 'red'">{{ $helpers.formatNumber(parseInt(item.movingavg_short).toFixed(0)) }}%</v-chip> -->
                    <span class="percent_col">
                        <v-icon color="primary" small v-if="item.movingavg_short > 0">mdi-arrow-up-thin</v-icon>
                        <v-icon color="red" small v-else>mdi-arrow-down-thin</v-icon>
                        <span class="my-auto"> {{ $helpers.formatNumber(parseInt(item.movingavg_short).toFixed(0)) }}%</span>
                    </span>
                </template>

                <template v-slot:item.movingavg_long="{ item }">
                    <!-- <v-chip dark :color="item.monthPercentChange > 0 ? 'green' : 'red'">{{ $helpers.formatNumber(parseInt(item.movingavg_long).toFixed(0)) }}%</v-chip> -->
                    <span class="percent_col">
                        <v-icon color="primary" small v-if="item.movingavg_long > 0">mdi-arrow-up-thin</v-icon>
                        <v-icon color="red" small v-else>mdi-arrow-down-thin</v-icon>
                        <span class="my-auto"> {{ $helpers.formatNumber(parseInt(item.movingavg_long).toFixed(0)) }}%</span>
                    </span>
                </template>
            </v-data-table>
        </v-layout>
    </div>
</template>

<script>
    import { sv } from "vuetify/lib/locale"

    export default {
        name: "Trends",
        props: {
            keywords: Array,
            mountedOn: String,
            resize: Number,
            keyword_table_headers: Object,
            cardData: Object
        },
        data() {
            return {
                keywordCount: 0,
                title: "Keyword Trends for ",
                tableHeightTrend: 0,
                selected: [],
                rows: [],
                headers: [
                    {
                        text: "Keyword",
                        align: "start",
                        value: "keyword",
                        width: "200px",
                        active: true
                    },
                    {
                        text: "⌀ Google SV",
                        align: "center",
                        value: "avgsv",
                        width: "150px",
                        active: true
                    },
                    //
                    {
                        text: "Trend",
                        value: "trend",
                        width: "120px",
                        sortable: false,
                        active: true
                    },
                    {
                        text: "Short Term Trend",
                        value: "movingavg_short",
                        align: "center",
                        tooltip: "The short term trend indicates how much the search volume of the last 2 months has increased compared to the average search volume of the previous months.",
                        active: true
                    },
                    // {
                    //     text: "Long Term Trend",
                    //     value: "movingavg_long",
                    //     align: "center",
                    //     tooltip: "Search volume change of the last 12 months, previous year compared to the last 12 months, current year"
                    // },
                    {
                        text: "Last Year Month",
                        value: "lastYearMonth",
                        align: "center",
                        tooltip: "Search volume of the last month, previous year",
                        active: true
                    },
                    {
                        text: "Month",
                        value: "latestYearMonth",
                        align: "center",
                        tooltip: "Search volume of the last month, current year",
                        active: true
                    },
                    {
                        text: "Month Change",
                        value: "monthChange",
                        align: "center",
                        tooltip: "Search volume change last month, previous year compared to the last month, current year",
                        active: true
                    },
                    {
                        text: "Month Change in %",
                        value: "monthPercentChange",
                        align: "center",
                        tooltip: "Search volume change last month, previous year compared to the last month, current year",
                        active: true
                    },
                    {
                        text: "",
                        value: "sumLastYear",
                        align: "center",
                        tooltip: "Search volume of the last 12 months, previous year",
                        active: true
                    },
                    {
                        text: "",
                        value: "sumLatestYear",
                        align: "center",
                        tooltip: "Search volume of the last 12 months, current year",
                        active: true
                    },
                    {
                        text: "Year Change",
                        value: "diff",
                        align: "center",
                        tooltip: "Search volume change of the last 12 months, previous year compared to the last 12 months, current year",
                        active: true
                    },
                    {
                        text: "Year Change in %",
                        value: "percent",
                        align: "center",
                        tooltip: "Search volume change of the last 12 months, previous year compared to the last 12 months, current year",
                        active: true
                    }
                ],
                gradient: [this.$vuetify.theme.themes.light.sparklineGradient1, this.$vuetify.theme.themes.light.sparklineGradient2, this.$vuetify.theme.themes.light.sparklineGradient3],
                sortBy: "movingavg_short",
                sortDesc: true,
                search: "",
                hide: false
            }
        },
        watch: {
            keywords() {
                this.selected = []
                console.log("process keyword trends")
                this.processData()
            },
            resize() {
                this.onResize()
            }
        },
        methods: {
            customSort(items, index, isDesc) {
                let sortedItems = this.$helpers.globalSort(items, index, isDesc, this.headers, null, "research_trends")
                console.log(sortedItems)
                return sortedItems
            },
            processData() {
                if (!this.keywords || this.keywords.length == 0) {
                    return
                }
                const maxSVlength = this.keywords[0].search_volumes.length < 24 ? 12 : 24 // check if old research or brand dashboard with only 12 month sv ->
                const halfLength = maxSVlength / 2
                console.log(this.keywords)
                let keywords = this.keywords.filter((keyword) => keyword.search_volumes.length > maxSVlength - 1 && keyword.search_volumes[0].count > 10)
                this.rows = keywords.map((keyword) => {
                    let row = {}
                    let svarray = JSON.parse(JSON.stringify(keyword.search_volumes))
                    row.keyword = keyword.keyword
                    row.avgsv = keyword.avgsv
                    row.trend = svarray.reverse().map((entry) => parseInt(entry.count))
                    row.lists = keyword.lists
                    row.latestYearMonth = svarray[svarray.length - 1]
                    row.lastYearMonth = svarray[svarray.length - 1 - halfLength]
                    row.first12 = svarray.splice(svarray.length - halfLength, halfLength)
                    row.second12 = svarray.splice(svarray.length - halfLength, halfLength)
                    row.in_clipboard = keyword.in_clipboard
                    row.sumLatestYear = row.first12.reduce(function (sum, value) {
                        return sum + value.count
                    }, 0)
                    row.sumLastYear = row.second12.reduce(function (sum, value) {
                        return sum + value.count
                    }, 0)
                    row.monthChange = row.latestYearMonth.count - row.lastYearMonth.count
                    row.previousYearMonth = keyword.search_volumes[maxSVlength]
                    // console.log("row previousyearmonth", row.previousYearMonth)
                    if (row.monthChange == 0) {
                        row.monthPercentChange = 0
                    } else {
                        row.monthPercentChange = 100 * (row.monthChange / row.lastYearMonth.count)
                    }
                    row.diff = row.sumLatestYear - row.sumLastYear
                    row.percent = (100 * (row.sumLatestYear - row.sumLastYear)) / row.sumLastYear

                    row.movingavg_short = this.calcMovingAVG(JSON.parse(JSON.stringify(keyword.search_volumes)), 2, maxSVlength)
                    row.movingavg_long = this.calcMovingAVG(JSON.parse(JSON.stringify(keyword.search_volumes)), halfLength, maxSVlength)

                    return row
                })
                this.rows.push({})
                this.rows.pop()
                console.log(this.rows)
                this.keywordCount = this.rows.length
                this.setDynamicHeader(this.rows[0])
            },
            calcMovingAVG(svarray, short, long) {
                let sumlastshort = 0
                let sumlastlong = 0
                for (let index = 0; index < long; index++) {
                    sumlastlong += svarray[index].count
                    if (index < short) sumlastshort += svarray[index].count
                }
                let avglastshort = sumlastshort / short
                let avglastlong = sumlastlong / long
                return (100 * (avglastshort - avglastlong)) / avglastlong
            },

            setDynamicHeader(row) {
                if (!row) return
                let monthArray = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

                // setting Month Header Title
                let year = row.latestYearMonth.year
                let month = row.latestYearMonth.month
                let lastYear = row.lastYearMonth.year
                let lastYearMonth = row.lastYearMonth.month
                let monthIndex = this.headers.findIndex((header) => header.value == "latestYearMonth")
                let lastYearMonthIndex = this.headers.findIndex((header) => header.value == "lastYearMonth")
                this.headers[monthIndex].text = monthArray[month] + " " + year
                console.log("month", monthArray[month])
                console.log("year", year)

                this.headers[lastYearMonthIndex].text = monthArray[lastYearMonth] + " " + lastYear
                console.log("month", monthArray[lastYearMonth])
                console.log("year", year)

                // setting Year Header Title
                let sumLatestYearIndex = this.headers.findIndex((header) => header.value == "sumLatestYear")
                let sumLastYearIndex = this.headers.findIndex((header) => header.value == "sumLastYear")
                console.log(sumLastYearIndex)

                this.headers[sumLatestYearIndex].text =
                    monthArray[lastYearMonth < 12 ? lastYearMonth + 1 : 1] + " " + (lastYearMonth < 12 ? lastYear : lastYear + 1) + " - " + monthArray[month] + " " + year
                this.headers[sumLastYearIndex].text =
                    monthArray[row.previousYearMonth.month < 12 ? row.previousYearMonth.month + 1 : 1] +
                    " " +
                    (row.previousYearMonth.month < 12 ? row.previousYearMonth.year : row.previousYearMonth.year + 1) +
                    " - " +
                    monthArray[lastYearMonth] +
                    " " +
                    lastYear

                console.log("month", monthArray[row.previousYearMonth.month < 12 ? row.previousYearMonth.month + 1 : 1])
                console.log("lastYearMonth", row.previousYearMonth.month)
                console.log("lastYearMonthlogik", row.previousYearMonth.month < 12 ? row.previousYearMonth.year : row.previousYearMonth.year + 1)
            },
            selectAll() {
                var visibleRows = this.$refs.trend_table.$children[0].filteredItems
                console.log(visibleRows)
                console.log(this.rows)
                if (this.selected.length) {
                    // unselect
                    this.selected = []

                    this.rows.map((x) => {
                        x.selected = false
                    })
                } else {
                    this.rows.map((x) => {
                        x.selected = false
                    })
                    // select
                    this.selected = visibleRows
                    visibleRows.map((row) => {
                        this.rows.map((x) => {
                            if (row.keyword == x.keyword) {
                                x.selected = true
                            }
                        })
                    })
                    console.log(this.selected)
                    console.log(this.rows)
                }
            },
            addToChart(sparkline_clicked) {
                console.log(sparkline_clicked)
                let keywordsForChart = []
                if (!sparkline_clicked) {
                    console.log("not clicked")
                    keywordsForChart = this.selected.map((selectedTrendKeyword) => {
                        return this.keywords.find((keywordrow) => keywordrow.keyword == selectedTrendKeyword.keyword)
                    })
                } else {
                    keywordsForChart.push(this.keywords.find((keywordrow) => keywordrow.keyword == sparkline_clicked.keyword))
                }
                console.log(keywordsForChart)
                this.$emit("addToChart", keywordsForChart)
                return
            },

            onResize() {
                console.log(this.mountedOn)
                setTimeout(() => {
                    const windowHeight = window.innerHeight
                    const windowWidth = window.innerWidth

                    // Check if window height is less than 800
                    if (windowHeight < 800 || windowWidth < 1264) {
                        this.tableHeightTrend = "auto" // Allow table to take the height it needs
                        return
                    }
                    if (!this.$store.state.user_settings.advancedMode || this.mountedOn == "Brandindex") {
                        const navHeight = 48 + 30 // + main padding
                        const footerHeight = 59
                        const tabHeight = 40
                        const padding = 25
                        const windowHeight = window.innerHeight

                        const rowElement = document.querySelector("td:nth-child(2)")

                        const table_actions = document.getElementById("table_actions_trends")
                        const tableHeader = document.getElementsByClassName("text-start")

                        if (table_actions && rowElement && rowElement.offsetHeight) {
                            const tabelHeight = windowHeight - navHeight - footerHeight - tabHeight - table_actions.offsetHeight

                            const allRowHeight = rowElement.offsetHeight * this.rows.length
                            const tableInnerHeight = tabelHeight - tableHeader[0].offsetHeight

                            if (allRowHeight < tableInnerHeight) {
                                this.tableHeightTrend = allRowHeight + tableHeader[0].offsetHeight
                            } else {
                                this.tableHeightTrend = windowHeight - navHeight - footerHeight - tabHeight - table_actions.offsetHeight - padding
                            }
                        }
                        console.log(this.tableHeightTrend)
                    } else {
                        // window.scrollTo(0, 0)
                        const table_actions = document.getElementById("table_actions_trends")
                        if (table_actions) {
                            const table_actions_position = table_actions.getBoundingClientRect()
                            const table_actions_height = table_actions.offsetHeight
                            const footerHeight = 52
                            const windowHeight = window.innerHeight
                            this.tableHeightTrend = windowHeight - table_actions_position.y - footerHeight - 12 - table_actions_height - 8
                        }
                    }
                }, 100)
            },
            addToClipboard() {
                let keywordsForClipboard = this.selected.map((selectedTrendKeyword) => {
                    return this.keywords.find((keywordrow) => keywordrow.keyword == selectedTrendKeyword.keyword)
                })
                this.$emit("addToClipboard", keywordsForClipboard)
            }
        },
        mounted() {
            this.onResize()
            this.processData()
        }
    }
</script>

<style lang="scss">
    #trend_table table > tbody > tr > td:nth-child(1) {
        z-index: 2 !important;
    }
    #trend_table table > tbody > tr > td:nth-child(2) {
        z-index: 2 !important;
    }
    #trend_table table > thead > tr > th:nth-child(2) {
        z-index: 3 !important;
    }
    #trend_table table > thead > tr > th:nth-child(1) {
        position: sticky !important;
        position: -webkit-sticky !important;
        left: 0 !important;
        z-index: 3 !important;
        background: var(--v-keywordcol-base) !important;
    }

    #trend_table table > tbody > tr > td:nth-child(1),
    #trend_table table > tbody > tr > th:nth-child(1) {
        position: sticky !important;
        position: -webkit-sticky !important;
        left: 0 !important;
        background: var(--v-keywordcol-base) !important;
    }

    #trend_table table > tbody > tr > td:nth-child(2),
    #trend_table table > thead > tr > th:nth-child(2) {
        position: sticky !important;
        position: -webkit-sticky !important;
        left: 53px !important;
        background: var(--v-keywordcol-base) !important;
        border-right: 1.5px solid rgb(218, 218, 218);
    }
    #trend_table table > thead > tr > th {
        background-color: var(--v-keywordcol-base);
    }
    #trend_table .v-data-footer {
        /* background-color: #f3f3f3; */
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
        padding-right: 65px;
    }
    .percent_col {
        white-space: nowrap;
    }

    .trend_container {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .trend_overlay {
        // border-top-left-radius: 10px;
        // border-bottom-left-radius: 10px;
        position: absolute;
        bottom: 0;
        left: 100%;
        right: 0;
        // background: rgb(0, 160, 137);
        // background: linear-gradient(
        //     90deg,
        //     rgba(0, 160, 137, 1) 6%,
        //     rgba(0, 160, 137, 0.9346332282913166) 24%,
        //     rgba(0, 160, 137, 0.6909357492997199) 46%,
        //     rgba(101, 198, 184, 0.671327906162465) 66%,
        //     rgba(101, 198, 184, 0.29597776610644255) 100%
        // );
        background: rgba(0, 160, 137, 0.3);
        -webkit-backdrop-filter: blur(2px) !important;
        backdrop-filter: blur(2px) !important;
        // opacity: 0.8;
        overflow: hidden;
        width: 0;
        height: 100%;
        transition: 0.3s ease;
    }

    .trend_container:hover .trend_overlay {
        width: 100%;
        left: 0;
    }
    .sparkline {
        width: 100%;
        height: 100%;
        display: flex;
    }
    .trend_text {
        text-transform: uppercase;
        color: white;
        font-weight: bold;
        font-size: 8px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        white-space: nowrap;
    }
    #trend_table table > thead > tr > td:nth-child(1) {
        padding-left: 25px !important;
        padding-right: 5px;
    }
    #trend_table table > tbody > tr > td:nth-child(1) > div > i {
        font-size: 20px !important;
        padding-left: 2px !important;
    }
</style>
