var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.$store.state.user_settings.advancedMode)?_c('div',{staticClass:"v-card__title sheet-card-title pt-2 pb-1",attrs:{"id":"tabletitle"}},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.cardData.searchPhrases)?_c('span',{staticClass:"pr-5 sheet-card-title"},[_c('span',{staticClass:"kursiv ml-1"},[_vm._v(" \""+_vm._s(_vm.cardData.searchPhrases[0])+"\"")])]):_vm._e()]):_vm._e(),_c('v-row',{staticClass:"sheet-card-title v-card__subtitle pt-3 pb-2",attrs:{"no-gutters":"","id":"card__subtitle"}},[(_vm.selected.length > 0)?_c('span',[_c('v-chip',{staticClass:"white--text",attrs:{"color":"primary","small":""}},[_vm._v(" "+_vm._s(_vm.selected.length)+" of "+_vm._s(_vm.keywordCount)+" selected")])],1):_c('span',{staticClass:"mt-auto"},[_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.keywordCount)+" keywords")])],1),_c('v-text-field',{ref:"searchfield",staticClass:"adv_mode_searchbar",staticStyle:{"width":"40%"},attrs:{"color":"primary","append-icon":"mdi-magnify","label":"Search table...","hide-details":"","filled":"","dense":"","rounded":"","single-line":"","clearable":"","clear-icon":"mdi-close"},on:{"click:clear":function($event){{
                    _vm.search = ''
                }}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-row',{staticClass:"px-4 pb-1 pt-0",attrs:{"no-gutters":"","id":"table_actions_trends"}},[_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-1 mr-1",attrs:{"outlined":_vm.$store.state.user_settings.advancedMode ? false : true,"small":_vm.$store.state.user_settings.advancedMode ? false : true,"icon":_vm.$store.state.user_settings.advancedMode ? true : false,"color":"primary","id":"select_all_btn"},on:{"click":_vm.selectAll}},on),[_c('v-icon',{attrs:{"color":"primary","dark":""}},[_vm._v(_vm._s(!_vm.selected.length ? "mdi-checkbox-multiple-marked-outline" : "mdi-checkbox-multiple-blank-outline"))])],1)]}}])},[_c('span',[_vm._v(_vm._s(!_vm.selected.length ? "Select all" : "Unselect all"))])]),_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.$store.state.user_settings.advancedMode},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(_vm.mountedOn != 'SharedResearch')?_c('v-btn',_vm._g({staticClass:"mr-1 mb-1",attrs:{"id":"add_clipboard_btn","outlined":_vm.$store.state.user_settings.advancedMode ? false : true,"small":_vm.$store.state.user_settings.advancedMode ? false : true,"icon":_vm.$store.state.user_settings.advancedMode ? true : false,"color":"primary"},on:{"click":_vm.addToClipboard}},on),[(!_vm.$store.state.user_settings.advancedMode)?_c('span',[_vm._v(" add to clipboard")]):_vm._e(),_c('v-icon',{class:!_vm.$store.state.user_settings.advancedMode ? 'ml-1' : ''},[_vm._v("mdi-text-box-plus-outline")])],1):_vm._e()]}}])},[_c('span',[_vm._v("add to clipboard")])]),_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.$store.state.user_settings.advancedMode},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1 mb-1",attrs:{"id":"add_chart_btn","outlined":_vm.$store.state.user_settings.advancedMode ? false : true,"small":_vm.$store.state.user_settings.advancedMode ? false : true,"icon":_vm.$store.state.user_settings.advancedMode ? true : false,"color":"primary","disabled":_vm.selected.length > 11},on:{"click":function($event){return _vm.addToChart(null)}}},on),[(!_vm.$store.state.user_settings.advancedMode)?_c('span',[_vm._v(" add to chart")]):_vm._e(),_c('v-icon',{class:!_vm.$store.state.user_settings.advancedMode ? 'ml-1' : ''},[_vm._v("mdi-chart-timeline-variant")])],1)]}}])},[_c('span',[_vm._v("add to chart")])]),_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.$store.state.user_settings.advancedMode},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(_vm.$store.state.user_settings.advancedMode)?_c('v-btn',_vm._g({staticClass:"mr-1 mb-1",attrs:{"outlined":_vm.$store.state.user_settings.advancedMode ? false : true,"small":_vm.$store.state.user_settings.advancedMode ? false : true,"icon":_vm.$store.state.user_settings.advancedMode ? true : false,"color":"primary","disabled":_vm.selected.length > 1},on:{"click":function($event){return _vm.$emit('fetchSERP', _vm.selected)}}},on),[_c('v-icon',{class:!_vm.$store.state.user_settings.advancedMode ? 'ml-1' : ''},[_vm._v("mdi-feature-search-outline")])],1):_vm._e()]}}])},[_c('span',[_vm._v("load SERP")])]),_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.$store.state.user_settings.advancedMode},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1 mb-1",attrs:{"id":"add_chart_btn","outlined":_vm.$store.state.user_settings.advancedMode ? false : true,"small":_vm.$store.state.user_settings.advancedMode ? false : true,"icon":_vm.$store.state.user_settings.advancedMode ? true : false,"color":"primary"},on:{"click":function($event){return _vm.$emit('exportTrends', _vm.selected.length ? _vm.selected : _vm.rows, _vm.headers)}}},on),[(!_vm.$store.state.user_settings.advancedMode)?_c('span',[_vm._v("export")]):_vm._e(),_c('v-icon',{class:!_vm.$store.state.user_settings.advancedMode ? 'ml-1' : ''},[_vm._v("mdi-cloud-download")])],1)]}}])},[_c('span',[_vm._v("export")])])],1)],1),_c('v-layout',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"fluid":"","child-flex":""}},[_c('v-data-table',{ref:"trend_table",staticClass:"elevation-0",attrs:{"id":"trend_table","height":_vm.tableHeightTrend,"search":_vm.search,"show-select":"","fixed-header":"","headers":_vm.headers,"items":_vm.rows,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"item-key":"keyword","footer-props":{ 'items-per-page-options': [50, 100, 250] },"items-per-page":25,"custom-sort":_vm.customSort},on:{"update:search":function($event){_vm.search=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
                var header = ref.header;
return [(h.tooltip)?_c('v-tooltip',{key:h.value,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text)+" "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-help")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(h.tooltip))])]):_c('span',{key:h.value},[_vm._v(_vm._s(h.text))])]}}}),{key:"item.keyword",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"kw_table_td"},[_c('div',{staticClass:"mr-1"},[_c('span',[_vm._v(" "+_vm._s(item.keyword))])]),_c('div',{staticClass:"ml-auto",staticStyle:{"display":"flex"}},[(_vm.mountedOn != 'Research')?_c('div',{staticClass:"savedicon_wrapper"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"savedicon",attrs:{"small":"","color":item.lists ? 'primary' : item.in_clipboard ? '#e6a940' : 'white'}},on),[_vm._v("mdi-content-save")])]}}],null,true)},[(item.lists)?_c('span',[_c('div',{staticClass:"saved-in"},[_vm._v("Saved in:")]),_c('ul',_vm._l((item.lists),function(list,index){return _c('li',{key:index},[_vm._v(_vm._s(list))])}),0)]):(item.in_clipboard)?_c('span',[_c('div',{staticClass:"saved-in"},[_vm._v("in clipboard")])]):_c('span',[_c('div',{staticClass:"saved-in"},[_vm._v("Not saved")])])])],1):_vm._e()])])]}},{key:"item.avgsv",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.$helpers.formatNumber(item.avgsv)))])]}},{key:"item.trend",fn:function(ref){
                var item = ref.item;
return [_c('v-row',_vm._b({attrs:{"no-gutters":""}},'v-row',(_vm.sparklineData = { data: item.trend.slice(-12) }),false),[_c('v-col',{staticClass:"d-flex",staticStyle:{"position":"relative"}},[_c('v-sparkline',{staticStyle:{"max-width":"68px","max-height":"45px"},attrs:{"value":_vm.sparklineData.data,"color":"primary","padding":"2","smooth":"20","gradient-direction":"top","fill":false}}),_c('v-sparkline',{staticStyle:{"max-width":"68px","max-height":"45px","position":"absolute"},attrs:{"value":_vm.sparklineData.data,"gradient":_vm.gradient,"padding":"2","smooth":"20","gradient-direction":"top","fill":true}})],1)],1)]}},{key:"item.sumLatestYear",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(_vm.$helpers.formatNumber(item.sumLatestYear)))])]}},{key:"item.sumLastYear",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(_vm.$helpers.formatNumber(item.sumLastYear)))])]}},{key:"item.percent",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":item.percent > 0 ? 'green' : 'red'}},[_vm._v(_vm._s(_vm.$helpers.formatNumber(item.percent))+" "),(item.percent != Infinity)?_c('span',[_vm._v("% ")]):_vm._e()])]}},{key:"item.diff",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":item.diff > 0 ? 'green' : 'red'}},[_vm._v(_vm._s(_vm.$helpers.formatNumber(item.diff))+" ")])]}},{key:"item.latestYearMonth",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(_vm.$helpers.formatNumber(item.latestYearMonth.count)))])]}},{key:"item.lastYearMonth",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(_vm.$helpers.formatNumber(item.lastYearMonth.count)))])]}},{key:"item.monthPercentChange",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":item.monthPercentChange > 0 ? 'green' : 'red'}},[_vm._v(_vm._s(_vm.$helpers.formatNumber(item.monthPercentChange))),(item.percent != Infinity)?_c('span',[_vm._v("% ")]):_vm._e()])]}},{key:"item.monthChange",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":item.monthChange > 0 ? 'green' : 'red'}},[_vm._v(_vm._s(_vm.$helpers.formatNumber(item.monthChange)))])]}},{key:"item.movingavg_short",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"percent_col"},[(item.movingavg_short > 0)?_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-arrow-up-thin")]):_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-arrow-down-thin")]),_c('span',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(_vm.$helpers.formatNumber(parseInt(item.movingavg_short).toFixed(0)))+"%")])],1)]}},{key:"item.movingavg_long",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"percent_col"},[(item.movingavg_long > 0)?_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-arrow-up-thin")]):_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-arrow-down-thin")]),_c('span',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(_vm.$helpers.formatNumber(parseInt(item.movingavg_long).toFixed(0)))+"%")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }