import Vue from "vue"
import VueRouter from "vue-router"
// import Home from '../views/Home.vue'
import auth from "../components/helper/auth"
import store from "../store/index"
import multiguard from "vue-router-multiguard"
const CryptoJS = require("crypto-js")
import vuetify from "../plugins/vuetify" // Stelle sicher, dass der Pfad zur Vuetify-Konfiguration korrekt ist
import { customThemes } from "../plugins/themes"
const requireAuth = function (to, from, next) {
    next()
    auth.loggedIn().then((response) => {
        if (response.no_redirect) return
        if (!response) {
            console.log("no response auth")
            delete localStorage.token
            delete localStorage.user
            delete localStorage.id
            delete localStorage.company_id
            delete localStorage.plan
            delete localStorage.username
            next({
                path: "/login",
                query: { redirect: to.fullPath }
            })
        }
    })
}

const isActionAllowed = async function (to, from, next) {
    if (store.state.company_status == undefined) {
        for (let index = 0; index < 1; index++) {
            console.log("await")
            await timeout(100)
            if (store.state.company_status == undefined && localStorage.token !== undefined) {
                index -= 1
            }
        }
    }
    console.log("HERE")
    // console.log(from)
    // console.log(store.state.user_rights)
    let allowedLandingPage = store.state.content_suite || store.state.research_suite || store.state.monitoring_suite ? "/" : "/login"

    if (to.path.includes("/content-project") || to.path.includes("/content-projects") || to.path.includes("/wdf-idf") || to.path.includes("/briefing") || to.path.includes("/content-editor")) {
        console.log("CS ENTRYYYY")
        if (store.state.content_suite) {
            next()
        } else {
            next({ path: allowedLandingPage })
        }
    } else if (to.path.includes("/monitoring") || to.path.includes("/quickseo")) {
        if (store.state.monitoring_suite) {
            next()
        } else {
            next({ path: allowedLandingPage })
        }
    } else {
        if (to.path == "/settings") {
            if (store.state.admin) {
                next()
            } else {
                next({ path: allowedLandingPage })
            }
        } else {
            if (store.state.research_suite) {
                let right_id = 0
                if (to.name == "Collections") {
                    right_id = 1
                } else if (to.name == "Research") {
                    right_id = 1
                } else if (to.name == "Ideas") {
                    right_id = 8
                } else if (to.name == "Suggests") {
                    right_id = 9
                } else if (to.name == "Searchvolume") {
                    right_id = 10
                } else if (to.name == "Questions") {
                    right_id = 11
                } else if (to.name == "Brands") {
                    right_id = 12
                } else if (to.name == "Brand-Index") {
                    right_id = 12
                } else if (to.name == "Settings") {
                    right_id = 17
                } else if (to.name == "Add-Dashboard") {
                    right_id = 25
                }
                console.log(to)
                console.log(store.state.user_rights)

                let found_rights = store.state.user_rights.find((x) => x.rightID === right_id)
                let admin = store.state.admin
                console.log("ADMIN", admin)

                // console.log(found_rights)
                if (found_rights !== undefined || admin) {
                    next()
                } else {
                    console.log("Action not allowed")

                    //redirect to next allowed page
                    // if(from.name == "Login"){
                    let availablePages = [1, 8, 9, 10, 11, 12, 17]
                    let allowedPage = store.state.user_rights.find((x) => availablePages.indexOf(x.rightID) > -1)
                    if (allowedPage.rightID == 1) {
                        next({ path: "/collections" })
                    } else if (allowedPage.rightID == 8) {
                        next({ path: "/ideas" })
                    } else if (allowedPage.rightID == 9) {
                        next({ path: "/suggests" })
                    } else if (allowedPage.rightID == 10) {
                        next({ path: "/searchvolume" })
                    } else if (allowedPage.rightID == 11) {
                        next({ path: "/questions" })
                    } else if (allowedPage.rightID == 12) {
                        next({ path: "/brands" })
                    } else if (allowedPage.rightID == 17) {
                        next({ path: "/settings" })
                    } else if (allowedPage == undefined) {
                        next({ path: "/login" })
                    }
                    // }
                }
            } else {
                next({ path: allowedLandingPage })
            }
        }
    }
}

const isAllowedInPlan = async function (to, from, next) {
    console.log("plancheck")
    if (store.state.user_plan == undefined || store.state.user_rights.length == 0) {
        for (let index = 0; index < 1; index++) {
            console.log("await")
            await timeout(100)
            if (store.state.user_plan == undefined || store.state.user_rights.length == 0) {
                index -= 1
            }
        }
    }
    let plan = store.state.user_plan
    console.log("userplan", plan)
    if (plan == 1) {
        // kein brand Dashboard
        if (to.name == "Brands" || to.name == "Brand-Index") {
            //redirect to next allowed page
            console.log("Action not allowed")
            let availablePages = [1, 8, 9, 10, 11, 12, 17]
            let allowedPage = store.state.user_rights.find((x) => availablePages.indexOf(x.rightID) > -1)
            let admin = store.state.admin
            if (allowedPage.rightID == 1) {
                next({ path: "/collections" })
            } else if (allowedPage.rightID == 8) {
                next({ path: "/ideas" })
            } else if (allowedPage.rightID == 9) {
                next({ path: "/suggests" })
            } else if (allowedPage.rightID == 10) {
                next({ path: "/searchvolume" })
            } else if (allowedPage.rightID == 11) {
                next({ path: "/questions" })
            } else if (allowedPage.rightID == 12) {
                next({ path: "/brands" })
            } else if (allowedPage.rightID == 17 || admin) {
                next({ path: "/settings" })
            } else if (allowedPage == undefined) {
                next({ path: "/login" })
            }
        } else {
            next()
        }
    } else if (plan == 2) {
        //kein Dashboard und kein historical
        if (to.name == "Questions" || to.name == "Brands" || to.name == "Brand-Index") {
            //redirect to next allowed page
            console.log("Action not allowed")
            let availablePages = [1, 8, 9, 10, 11, 12, 17]
            let allowedPage = store.state.user_rights.find((x) => availablePages.indexOf(x.rightID) > -1)
            let admin = store.state.admin
            if (allowedPage.rightID == 1) {
                next({ path: "/collections" })
            } else if (allowedPage.rightID == 8) {
                next({ path: "/ideas" })
            } else if (allowedPage.rightID == 9) {
                next({ path: "/suggests" })
            } else if (allowedPage.rightID == 10) {
                next({ path: "/searchvolume" })
            } else if (allowedPage.rightID == 11) {
                next({ path: "/questions" })
            } else if (allowedPage.rightID == 12) {
                next({ path: "/brands" })
            } else if (allowedPage.rightID == 17 || admin) {
                next({ path: "/settings" })
            } else if (allowedPage == undefined) {
                next({ path: "/login" })
            }
        } else {
            next()
        }
    } else if (plan == 3) {
        // TODO: noch einfügen wenn mehr Optionen als User Management auf Settings Seite || Single User Licence - keine Settings Seite, keine Brand Dashboard
        if (to.name == "Settings" || to.name == "Brands" || to.name == "Brand-Index") {
            //redirect to next allowed page
            console.log("Action not allowed")
            let availablePages = [1, 8, 9, 10, 11, 12, 17]
            let allowedPage = store.state.user_rights.find((x) => availablePages.indexOf(x.rightID) > -1)

            if (allowedPage.rightID == 1) {
                next({ path: "/collections" })
            } else if (allowedPage.rightID == 8) {
                next({ path: "/ideas" })
            } else if (allowedPage.rightID == 9) {
                next({ path: "/suggests" })
            } else if (allowedPage.rightID == 10) {
                next({ path: "/searchvolume" })
            } else if (allowedPage.rightID == 11) {
                next({ path: "/questions" })
            } else if (allowedPage.rightID == 12) {
                next({ path: "/brands" })
            } else if (allowedPage.rightID == 17) {
                next({ path: "/settings" })
            } else if (allowedPage == undefined) {
                next({ path: "/login" })
            }
        } else {
            next()
        }
    } else {
        next()
    }
}
// const isContentSuiteAllowed = async function (to, from, next) {
//     if (store.state.content_suite) {
//         next()
//     } else {
//         next({ path: "/" })
//     }
// }
// const isMonitoringSuiteAllowed = async function (to, from, next) {
//     if (store.state.monitoring_suite) {
//         next()
//     } else {
//         next({ path: "/" })
//     }
// }

Vue.use(VueRouter)

const routes = [
    {
        path: "/collections",
        name: "Collections",
        component: () => import(/* webpackChunkName: "about" */ "../views/AllResearches.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/logout",
        beforeEnter(to, from, next) {
            auth.logout()
            next("/login")
        }
    },
    {
        path: "/sso:sso_code?",
        // beforeEnter (to, from, next) {
        //   ssoAuth
        // },
        beforeEnter: multiguard([ssoAuth])
    },
    {
        path: "/login:auth?",
        name: "Login",
        component: () => import(/* webpackChunkName: "about" */ "../views/Login.vue")
    },
    {
        path: "/setpassword:code?",
        name: "SetPasswordLogin",
        component: () => import(/* webpackChunkName: "about" */ "../views/SetPasswordLogin.vue")
    },
    {
        path: "/resetpassword:code?",
        name: "ResetPassword",
        component: () => import(/* webpackChunkName: "about" */ "../views/ResetPassword.vue")
    },
    {
        path: "/register",
        name: "Register",
        component: () => import(/* webpackChunkName: "about" */ "../views/Register.vue")
    },

    // {
    //   path: '/settings',
    //   name: 'Settings',
    //   component: () => import(/* webpackChunkName: "about" */ '../views/AdminPanel.vue'),
    //   beforeEnter: requireAuth,
    // },
    // {
    //   path: '/admin/monitoring',
    //   name: 'AdminMonitoring',
    //   component: () => import(/* webpackChunkName: "about" */ '../views/admin/Monitoring.vue'),
    //   beforeEnter: requireAuth,
    // },
    // {
    //   path: '/admin/user',
    //   name: 'AdminUser',
    //   component: () => import(/* webpackChunkName: "about" */ '../views/admin/User.vue'),
    //   beforeEnter: requireAuth,
    // },
    // {
    //   path: '/admin/panel',
    //   name: 'AdminPanel',
    //   component: () => import(/* webpackChunkName: "about" */ '../views/admin/Panel.vue'),
    //   beforeEnter: requireAuth,
    // },
    {
        path: "/settings",
        name: "Settings",
        component: () => import(/* webpackChunkName: "about" */ "../views/Settings.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed, isAllowedInPlan])
    },
    {
        path: "/research/:researchname",
        name: "Research",
        component: () => import(/* webpackChunkName: "about" */ "../views/Research.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/research/shared/:shareid",
        name: "SharedResearch",
        component: () => import(/* webpackChunkName: "about" */ "../views/SharedResearch.vue")
    },
    // {
    //   path: '/myresearches',
    //   name: 'MyResearches',
    //   component: () => import(/* webpackChunkName: "about" */ '../views/MyResearches.vue'),
    //   beforeEnter: requireAuth,
    // },
    // {
    //     path: "/rankings",
    //     name: "Rankings",
    //     component: () => import(/* webpackChunkName: "about" */ "../views/monitoring/Test.vue"),
    //     beforeEnter: requireAuth
    // },

    {
        path: "/pricing",
        name: "Pricing",
        component: () => import(/* webpackChunkName: "about" */ "../views/Pricing.vue"),
        beforeEnter: requireAuth
    },
    {
        path: "/quickseo/monitoring",
        name: "Monitoring Projects",
        component: () => import(/* webpackChunkName: "about" */ "../views/quickseo/MonitoringProjects.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/quickseo/monitoring/setup",
        name: "Setup",
        component: () => import(/* webpackChunkName: "about" */ "../views/quickseo/MonitoringSetup.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/quickseo/monitoring/project/:id",
        name: "Monitoring Project",
        component: () => import(/* webpackChunkName: "about" */ "../views/quickseo/MonitoringProject.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/monitoring",
        name: "Monitoring Projects",
        component: () => import(/* webpackChunkName: "about" */ "../views/monitoring/MonitoringProjects.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/monitoring/setup",
        name: "Setup",
        component: () => import(/* webpackChunkName: "about" */ "../views/monitoring/MonitoringSetup.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/monitoring/project/:id",
        name: "Monitoring Project",
        component: () => import(/* webpackChunkName: "about" */ "../views/monitoring/MonitoringProject.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/brand-index/:brandindex",
        name: "Brand-Index",
        component: () => import(/* webpackChunkName: "about" */ "../views/Brandindex.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed, isAllowedInPlan])
    },
    {
        path: "/brands",
        name: "Brands",
        component: () => import(/* webpackChunkName: "about" */ "../views/Brands.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed, isAllowedInPlan])
    },
    {
        path: "/brand-analysis/:brandindex",
        name: "Brand-Analysis",
        component: () => import(/* webpackChunkName: "about" */ "../views/Brandanalysis.vue"),
        beforeEnter: requireAuth
    },
    {
        path: "/add-dashboard",
        name: "Add-Dashboard",
        component: () => import(/* webpackChunkName: "about" */ "../views/AddBrandIndex.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/knowledge",
        name: "Knowledge Base",
        component: () => import("../views/KnowledgeBase.vue"),
        beforeEnter: multiguard([requireAuth])
    },
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    //   beforeEnter: requireAuth,
    // },
    // {
    //   path: '/keywordplanner',
    //   name: 'KeywordPlanner',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/KeywordPlanner.vue'),
    //   beforeEnter: requireAuth,
    // },
    {
        path: "/searchvolume",
        name: "Searchvolume",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/Searchvolume.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/questions",
        name: "Questions",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/Questions.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed, isAllowedInPlan])
    },
    {
        path: "/content-projects",
        name: "Content Projects",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/contentsuite/Projects.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/content-project/setup",
        name: "Project Setup",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/contentsuite/Setup.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/wdf-idf",
        name: "WDF-IDF Analyse",
        component: () => import(/* webpackChunkName: "about" */ "../views/contentsuite/AdhocWdfIdf.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/briefing/:project_id/:share_id?",
        name: "Briefing",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/contentsuite/Briefing.vue")
        // guard in mounted hook of component
    },
    {
        path: "/content-project/competitors/:project_id/:share_id?",
        name: "Competitor Analysis",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/contentsuite/Competitors.vue")
        // guard in mounted hook of component
    },
    {
        path: "/content-project/wdf-idf/:project_id/:share_id?",
        name: "WDF-IDF",
        component: () => import(/* webpackChunkName: "about" */ "../views/contentsuite/WdfIdf.vue")
        // guard in mounted hook of component
    },
    {
        path: "/content-editor/:project_id/:share_id?",
        name: "Content Editor",
        component: () => import(/* webpackChunkName: "about" */ "../views/contentsuite/ContentEditor.vue")
        // guard in mounted hook of component
    },
    {
        path: "/quickseo/internal-searches",
        name: "Internal Searches",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/internalSearches/InternalSearchesProjects.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/quickseo/internal-searches/project",
        name: "Internal Searches Project",
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/internalSearches/InternalSearchesProject.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/quickseo/internal-searches/setup",
        name: "Internal Searches Setup",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/internalSearches/InternalSearchesSetup.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/ideas",
        name: "Ideas",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/Ideas.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/",
        name: "Home",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/Home.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    // {
    //     path: "/advanced",
    //     name: "Advanced Search",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ "../views/AdvancedSearch.vue")
    //     // beforeEnter: multiguard([requireAuth, isActionAllowed])
    // },
    {
        path: "/suggests",
        name: "Suggests",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/Suggests.vue"),
        beforeEnter: multiguard([requireAuth, isActionAllowed])
    },
    {
        path: "/maintenance",
        name: "Maintenance",
        component: () => import("../views/Maintenance.vue")
    },
    {
        path: "/endoftest",
        name: "EndOfTest",
        component: () => import("../views/EndOfTest.vue")
    }
]

function ssoAuth(to, from, next) {
    console.log(to.params.sso_code)
    auth.ssoAuth(to.params.sso_code).then((response) => {
        if (!response || to.params.sso_code == undefined) {
            next({
                path: "/login"
            })
        } else {
            store.dispatch("FETCH_STARTUP_DATA", CryptoJS.AES.decrypt(response.user, process.env.VUE_APP_ENCRYPTION_PW).toString(CryptoJS.enc.Utf8))
            next("/")
        }
    })
}
function timeout(ms) {
    return new Promise((res) => setTimeout(res, ms))
}
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    console.log(to, from, next)
    //  ---------------------------------
    // Theme Switch for Custom Components
    //  ---------------------------------

    if (to.path.substring(0, 9) == "/quickseo" && from.path.substring(0, 9) != "/quickseo") {
        console.log("change theme")
        console.log(vuetify.framework.theme.themes.light)
        vuetify.framework.theme.themes.light = customThemes[1].light
        vuetify.framework.theme.themes.dark = customThemes[1].dark
        console.log(customThemes[1].light)
        console.log(vuetify.framework.theme.themes.light)
    }
    if (from.path.substring(0, 9) == "/quickseo" && to.path.substring(0, 9) != "/quickseo") {
        console.log("change theme back")
        vuetify.framework.theme.themes.light = customThemes[0].light
        vuetify.framework.theme.themes.dark = customThemes[0].dark
    }
    // --------------------------------

    if (from.name == "Ideas") {
        store.dispatch("SET_TOUR_RUNNING", false)
    }
    if (to.path != "/maintenance" && to.path != "/pricing") {
        if (store.state.company_status === "test_ended" && to.path != "/endoftest") {
            next({
                path: "/endoftest"
            })
        } else {
            next()
        }
        // TODO wieder einbauen wenn entsprechende API geplot
        // auth.apiOnline().then((response) => {
        //     console.log(response)
        //     if (!response && response !== undefined) {
        //         console.log("api offline, redirecting to update site")
        //         next({ path: "/maintenance" })
        //     }
        // })
        // } else if (store.state.company_status === "test_ended" && to.path != "/endoftest") {
        //     next({
        //         path: "/endoftest"
        //     })
    } else {
        next()
    }
})

export default router
